.autocomplete__item-group {
    background: white;
    box-shadow: 0 5px 10px 0 rgba(51,51,51,0.1);
    border-radius: .25rem;
}

.autocomplete__suggestion + .autocomplete__suggestion {
    border-top: 1px solid var(--color-lightgrey);
}

.autocomplete__dataset-header {
    font-family: var(--font-bold);
    margin-left: calc(20rem/16);
}

.autocomplete__suggestion {
    padding: calc(11rem/16) calc(20rem/16);
    cursor: pointer;
}

.autocomplete__message {
    margin-top: calc(11rem/16);
    padding: calc(11rem/16) calc(20rem/16);
}

.autocomplete__suggestion mark {
    padding: calc(4rem/16) 0;
}

.autocomplete__suggestion:hover {
    background: var(--color-darkgrey);
}

.auto-results-wrapper {
    padding: calc(74rem/16) calc(37rem/16) 1.5rem;
    border: 0;
    background: transparent;

    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    box-shadow: 0 10px 50px 0 rgba(0,0,0,0.1);
    border-radius: 4px;
}

.autocomplete__dataset + .autocomplete__dataset {
    margin-top: calc(16rem/16);
}

/* change the search list's overflow because one of the parents should be scrollable */
.auto-search-wrapper ul {
    overflow: visible;
}