.img-copyright{
    position: absolute;
    color: #FFF;
    line-height: 1;
    font-size: .75rem;
    cursor: pointer;
    z-index: 20;
    max-width: 92%;

    /* inline-layout override */
    display: block;
    height:auto;
    width:auto;
}

.img-copyright--left-top,
.img-copyright--top-left{
    top: calc(8rem/16);
    left: calc(8rem/16);
    right:auto;
    bottom:auto;
}

.img-copyright--right-top,
.img-copyright--top-right{
    top: calc(8rem/16);
    right: calc(8rem/16);
}

.img-copyright--left-bottom,
.img-copyright--bottom-left{
    bottom: calc(8rem/16);
    left: calc(8rem/16);
    right:auto;
    top:auto;
}

.img-copyright--right-bottom,
.img-copyright--bottom-right{
    bottom: calc(8rem/16);
    right: calc(8rem/16);
    left:auto;
    top:auto;
}

.img-copyright__icon{
    line-height: 1;
    padding: calc(5rem/16);
    margin: calc(-5rem/16);
    font-size: 1.125rem;
    text-shadow: 0 0 5px rgba(0, 0, 0, .7);
    position: relative;
    top:auto;
    left:auto;
    bottom:auto;
    right:auto;
}

@media (max-width: 767px) {
    .img-copyright__icon{
        font-size: .75rem;
        padding: calc(7rem/16);
    }
}

.img-copyright__content{
    line-height: 1;
    padding: .25rem;
    align-items: baseline;

    background: rgba(0,0,0,.5);
    color: white;
    display: none;
}

.img-copyright__close-icon{
    color: var(--color-text-default);
    font-size: calc(10rem/16);
}

.img-copyright__text+.img-copyright__close-icon,
.img-copyright__close-icon+.img-copyright__text{
    margin-left: calc(7rem/16);
}

.img-copyright.active .img-copyright__content{
    display: flex;
}

.img-copyright.active .img-copyright__icon{
    display: none;
}
