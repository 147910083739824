.wysiwyg table,
.table,{
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;

}

.wysiwyg table > thead > tr > th,
.wysiwyg table > tbody > tr > th,
.wysiwyg table > tfoot > tr > th,
.wysiwyg table > thead > tr > td,
.wysiwyg table > tbody > tr > td,
.wysiwyg table > tfoot > tr > td,
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td{
    padding: .5rem .75rem;
    text-align:left;
    background-color: #fff;
    border-top: 1px solid var(--color-grey);
    border-bottom: 1px solid var(--color-grey);
    @media screen and (min-width: 768px){
        padding: .75rem  1rem;
    }
}

.wysiwyg table > thead > tr > th,
.table > thead > tr > th {
    font-family: var(--font-bold);
}

.wysiwyg table > thead > tr > th:first-child,
.wysiwyg table > tbody > tr > th:first-child,
.wysiwyg table > tfoot > tr > th:first-child,
.wysiwyg table > thead > tr > td:first-child,
.wysiwyg table > tbody > tr > td:first-child,
.wysiwyg table > tfoot > tr > td:first-child,
.table > thead > tr > th:first-child,
.table > tbody > tr > th:first-child,
.table > tfoot > tr > th:first-child,
.table > thead > tr > td:first-child,
.table > tbody > tr > td:first-child,
.table > tfoot > tr > td:first-child{
    border-left: 1px solid var(--color-grey);
}

.wysiwyg table > thead > tr > th:last-child,
.wysiwyg table > tbody > tr > th:last-child,
.wysiwyg table > tfoot > tr > th:last-child,
.wysiwyg table > thead > tr > td:last-child,
.wysiwyg table > tbody > tr > td:last-child,
.wysiwyg table > tfoot > tr > td:last-child,
.table > thead > tr > th:last-child,
.table > tbody > tr > th:last-child,
.table > tfoot > tr > th:last-child,
.table > thead > tr > td:last-child,
.table > tbody > tr > td:last-child,
.table > tfoot > tr > td:last-child{
    border-right: 1px solid var(--color-grey);
}


/* vertical borders */
.table--vertical-borders > tbody > tr > td:not(:first-child),
.table--vertical-borders > thead > tr > th:not(:first-child) {
    border-left: 1px solid var(--color-grey-dark);
    border-right: 1px solid var(--color-grey-dark);
    border-top: none;
}

.table--vertical-borders > thead > tr > th,
.table--vertical-borders > tbody > tr > th {
    border-top: none;
}

.table--vertical-borders > thead > tr > th,
.table--vertical-borders > tbody + tbody {
    border-top: none;
    border-bottom: none;
}