.btn {
    border: none;
    font-family: var(--font-bold);
    font-size: calc(15rem/16);
    padding: calc(12rem/16) calc(18rem/16);
    border-radius: .25rem;
    transition: all 150ms ease;
    @media screen and (min-width: 768px) {
        font-size: 1rem;
        padding: calc(12rem/16) calc(35rem/16);
    }
}
.btn-md {
    font-size: calc(14rem/16);
    padding: calc(5rem/16) calc(18rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
        padding: calc(7rem/16) calc(22rem/16);
    }
}
.btn--top-edge {
    border-top-left-radius:0;
    border-top-right-radius:0;
}

.btn--bottom-edge {
    border-bottom-left-radius:0;
    border-bottom-right-radius:0;
}

.btn:focus {
    box-shadow: none;
}
.btn:focus,
.btn:focus-visible,
.btn:focus:active {
    color: #fff;
    outline: none;
}

.btn.btn-icon {
    padding-left: calc(10rem/16);
    padding-right: calc(10rem/16);
    @media screen and (min-width: 768px) {
        padding-left: calc(16rem/16);
        padding-right: calc(16rem/16);
    }
}

.btn.btn-icon .icon {
    vertical-align: -.15em;
}

.btn.btn-icon-left .icon,
.btn.btn-icon-right .icon{
    vertical-align: middle;
    position: relative;
    font-size:85%;
}
.btn.btn-icon-left .icon{
    margin-right:.375rem;
}
.btn.btn-icon-right .icon{
    margin-left:.375rem;
}


.btn.btn-primary{
    background-color: var(--color-primary);
    color: #fff;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus {
    background-color: var(--color-primary-dark);
    color: #fff;
}

.btn.btn-primary-outline{
    background-color: transparent;
    border: calc(1rem/16) solid var(--color-primary);
    color: var(--color-primary);
}

.btn.btn-primary-outline:hover,
.btn.btn-primary-outline:focus {
    background-color:var(--color-primary);
    color: #fff;
}

.btn.btn-white{
    background-color: #fff;
    color:var(--color-primary);
}

.btn.btn-white:hover,
.btn.btn-white:focus {
    background-color:var(--color-primary);
    color: #fff;
}



.btn.btn-white-outline{
    border-radius: 100rem;
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    font-family: var(--font-light);
}

.btn.btn-white-outline:hover,
.btn.btn-white-outline:focus {
    background-color: #fff;
    color: var(--color-primary);
}

.btn-cta,
.btn.btn-default{
    background-color: var(--color-secondary);
    color: #fff;
}
.btn-cta:hover,
.btn-cta:focus,
.btn.btn-default:hover{
    background-color: #c00000;
    color: #fff;
}

@media screen and (max-width: 767px) {
    .btn-cta--xs-sticky{
        position: fixed;
        left:0;
        right:0;
        bottom:0;
        z-index: 20;
        border-radius: 0;
    }
}

.btn-grey,
.btn-grey:disabled{
    background-color: var(--color-grey);
    border:1px solid var(--color-grey);
    color: #000;
}
.alert-info .btn-grey,
.alert-info .btn-grey:disabled{
    background-color: #d0d0d0;
    border: #d0d0d0;
}
.btn-grey:hover,
.btn-grey:active,
.btn-grey:focus{
    color: #000;
    background-color: var(--color-grey-dark);
    border:1px solid var(--color-grey-dark);
}

.btn.btn-xs{
    padding: calc(6rem/16) calc(25rem/16);
    font-size: calc(14rem/16);
}
.btn.btn-lg{
    padding: calc(11rem/16) calc(18rem/16);
    font-size: calc(16rem/16);
    @media screen and (min-width: 768px) {
        font-size: 1.25rem;
        padding: calc(14rem/16) calc(45rem/16);
    }
}

.btn.btn-link,
.btn.btn-link-plain{
    padding: 0;
    border: none;
    color:var(--color-primary);
    text-decoration: underline;
    font-family:inherit;
    font-size:inherit;
}
.btn.btn-link:active,
.btn.btn-link-plain:active{
    color:var(--color-primary);
}
.btn.btn-link:hover,
.btn.btn-link:focus{
    text-decoration: none;
}
.btn.btn-link-plain{
    text-decoration: none;
}
.btn.btn-link > .icon{
    vertical-align: -.15em;
}
.btn--show-disabled-state:disabled,
.btn--show-disabled-state.disabled {
    opacity: 0.8;
}
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}


@media screen and (max-width: 767px) {
    .btn-xs-block {
        display: block;
        width: 100%;
    }
}