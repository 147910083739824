.usp-item {
    padding:calc(15rem/16);
    line-height:1.33;
    color:var(--color-default);
    text-align: center;
    background: #fff;
    box-shadow: 0 22px 15px -20px rgba(0,0,0,0.5);
    height:100%;
}
.usp-item .icon {
    display: block;
    margin-bottom:.5rem;
    font-size:1.25rem;
    color:var(--color-primary);
}

.usp-item .icon.usp-item__info {
    font-size: calc(15rem/16);
    margin-bottom: 0;
    margin-top: .5rem;
}

@media screen and (min-width:768px) {
    .usp-item {
        padding:calc(30rem/16) calc(15rem/16);
        height:100%;
    }
}

@media screen and (min-width:1200px) {
    .usp-row.usp-row {
        margin-top:calc(40rem/16);
    }
    .usp-item {
        font-size:calc(20rem/16);
    }
    .usp-item .icon {
        font-size:2rem;
        margin-bottom:calc(18rem/16);
    }
}