.status {
    position: relative;
    overflow: hidden;
    background:#fff;
    padding:1rem;
    border-radius: .25rem;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
}

.status-bar {
    height:1rem;
    border-radius: 10rem;
    display: flex;
    overflow: hidden;
    background: var(--color-darkgrey);

    @media screen and (max-width: 767px) {
        margin: .5rem 0 1rem 0;
    }
}
.status-bar__progress {
    flex-direction: column;
    justify-content: center;
    border-radius: 10rem;
    background:var(--color-progress-partial);
}
.status-bar__progress--full {
    background:var(--color-progress-full);
}
.status-bar__progress--low {
    background:var(--color-progress-none);
}