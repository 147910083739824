.hero-additionals {
    /*border-bottom:1px solid #E3E3E3;*/
    padding-bottom: calc(30rem/16);

    @media screen and (min-width: 768px) and (max-width: 1199px){
        padding-bottom: calc(55rem/16);
    }
    @media screen and (min-width: 1200px){
        padding-bottom: calc(75rem/16);
    }
}


/* teaser */
.hero-additional-teaser {
    background: #fff;
    position: relative;
    padding:1.25rem;
    box-shadow: 0 5px 30px 0 rgba(51,51,51,0.1);
    height:100%;
    font-family: var(--font-default);
    &:hover {
        box-shadow: 0 5px 30px 0 rgba(51,51,51,0.25);
    }
    font-size: calc(15rem/16);
    line-height:1.5;
    @media screen and (min-width: 1200px) {
        padding:1.875rem;
    }
}
.hero-additional-teaser__body {
    margin-bottom:1.5rem;
}
.hero-additional-teaser__heading {
    font-size:1.25rem;
    margin:.75rem 0;
    color:var(--color-primary);
    font-family: var(--font-bold) !important;

    @media screen and (min-width: 1200px){
        font-size:1.5rem;
        margin:1rem 0;

    }
}
.hero-additional-teaser__img {
    margin-bottom:1.5rem;
    box-shadow: 0 22px 15px -20px rgba(0,0,0,0.5);
}
.hero-additional-teaser__bottom{
    margin-top: auto;
}

/* USPs */
.hero-usps {}
.hero-usps__body>li {
    position: relative;
    padding-left:2rem;
    margin-bottom: .5rem;

    @media screen and (min-width: 768px){
        font-size:1.125rem;
    }
    @media screen and (min-width: 1200px){
        font-size:1.25rem;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
.hero-usps__body>li:before {
    position: absolute;
    left:0;
    top:-.125rem;
    font-family: iconfont;
    color:var(--color-primary);
    content:var(--icon-rounded-checkmark);
    @media screen and (min-width: 1200px){
        top:0;
    }
}


/* ratings */
.hero-ratings {
    @media screen and (min-width: 1200px){
        font-size:1.125rem;
    }
}
.hero-usps__headline,
.hero-ratings__headline {
    position: relative;
    margin-bottom: 2rem;
    font-family: var(--font-bold);

    @media screen and (min-width: 768px){
        font-size:1.125rem;
    }
    @media screen and (min-width: 1200px){
        font-size:1.25rem;
        margin-bottom: 2rem;
    }
}
.hero-usps__headline:after,
.hero-ratings__headline:after {
    content:'';
    position: absolute;
    left:0;
    bottom:-1rem;
    width:9rem;
    height:calc(1rem / 16);
    background:#E3E3E3;

    @media screen and (min-width: 768px){
        font-size:1.125rem;
    }
    @media screen and (min-width: 1200px){
        font-size:1.25rem;
    }
}

.hero-ratings__info {
    font-family: var(--font-bold);
    font-size:.875rem;
}
.hero-ratings__flag {
    display: inline-block;
    vertical-align: middle;
    height: calc(30rem/16);
    width: calc(30rem/16);
}