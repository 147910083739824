.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__group {
    color:inherit;
    display: block;
}
.select2-container--bootstrap-5 .select2-selection {
    border:0;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
    font-family: var(--font-medium);
    padding-left:1.125rem;
}
.select2-container--bootstrap-5 .select2-selection--poi,
.select2-container--bootstrap-5 .select2-selection--search {
    padding-left:3.5rem;
}
.select2-container--bootstrap-5 .select2-selection--poi:before {
    content:var(--icon-poi);
    font-family: iconfont;
    font-size:1.125rem;
    position: absolute;
    left:1.125rem;
    top:.625rem;
}
.select2-container--bootstrap-5 .select2-selection--search:before {
    content:var(--icon-search);
    font-family: iconfont;
    font-size:1.125rem;
    position: absolute;
    left:1.125rem;
    top:.625rem;
}