.booking-widget-hero {
    @media (max-width: 767px) {
        margin-bottom: calc(20rem/16);
    }
}

.booking-widget__search-button.booking-widget__search-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(50rem/16);
    padding: 1rem;
}

.booking-widget-wrapper-col {
    display: flex;
}
.booking-widget-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: calc(40rem/16) calc(30rem/16);
    flex: 1;
    @media (min-width: 768px) {
        padding: 2rem;
    }
}
.booking-widget-wrapper__title.booking-widget-wrapper__title {
    color: white;
    font-size: calc(30rem/16);
    line-height: 1.2;
    margin-bottom: calc(4rem/16);
    @media (min-width: 768px) {
        font-size: calc(35rem/16);
    }
}
.booking-widget-wrapper__sub-title {
    color: white;
}
.booking-widget-wrapper__widget {
    width: 100%;
    margin-top: calc(16rem/16);
    @media (min-width: 768px) {
        margin-top: calc(35rem/16);
    }
}

@media (min-width: 768px) {
    .booking-widget__container {
        max-width: calc(683rem/16);
        margin: 0 auto;
    }

    .booking-widget__container-sm {
        max-width: calc(410rem/16);
        margin: 0 auto;
    }
}


.booking-widget__badge-holder {
    position: absolute;
    right:0;
    top:.75rem;
    z-index: 10;
}
.booking-widget__badge {
    margin-bottom:.25rem;
}
.booking-widget__top-seller-badge{
    background-color: var(--color-secondary);
    color: #fff;
    font-family: var(--font-bold);
    font-size: .875rem;
    line-height: 1.2;

    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    text-align: center;

    position: absolute;
    top: .75rem;
    left: .75rem;
    z-index: 11;
    transform: rotate(-15deg);
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
        top: 1.5rem;
        left: 1.5rem;
        font-size: 1.125rem;
        width: 6rem;
        height: 6rem;
    }
}

.booking-widget__top-seller-badge__inner{
    text-align: center;
    position: absolute;
    left:0;
    right:0;
    top: 50%;
    transform: translateY(-50%);
}

// todo: make sure everything above is visible (add padding-bottom to body?)
.booking-widget__error {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 0.75rem 2.1875rem;
    color: var(--color-validation-error);

    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: -1;

    opacity: 0;
    transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;
}

.booking-widget__error.show {
    transform: translateY(-100%);
    opacity: 1;
}