.header-affiliate {
    color:var(--color-default);
    font-size: calc(13rem / 16);
    line-height:calc(36rem / 16);
    height:calc(50rem / 16);
    padding: calc(7rem / 16) 0;
    overflow: hidden;
    background: #EAE9E9;
}

@media screen and (min-width: 768px){
    .header-affiliate {
        font-size: calc(15rem / 16);
    }
}
@media screen and (min-width: 1280px){
    .header-affiliate .container.container {
        max-width: calc(1870rem / 16);
        padding: 0 calc(30rem / 16);
    }
}
.header-affiliate__logo {
    display: inline-block;
    margin-right:calc(10rem / 16);
}

@media screen and (min-width: 768px){
    .header-affiliate__logo {
        margin-right:calc(30rem / 16);
    }
}
