.widget {
    background-color: #fff;
    border: 1px solid var(--color-darkgrey);
    border-radius: 0;

    @media screen and (min-width: 768px) {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}

.widget--padding {
    padding: calc(12rem/16);
}
.widget--blank {
    padding: 0;
    border:0;
}
.widget--grey {
    background-color: var(--color-grey);
}

.widget__header {
    background-color: var(--color-primary);
    border-radius: 0;
    color: #fff;
    font-family: var(--font-bold);
    font-size: 1.5rem;
    text-align: center;
    line-height: 1;
    padding: calc(18rem/16) calc(30rem/16);
    margin-bottom: 0;
}

.widget__body {
    line-height: calc(22rem/16);
    padding: calc(25rem/16) calc(30rem/16);
}


.widget__grey-wrapper {
    background-color: var(--color-grey);
}
@media screen and (min-width: 768px) {
    .widget__grey-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}

.widget .rating-list{
    background:#fff;
}


.rating-list__item {
    padding: calc(10rem/16) 0;
    margin: 0 calc(10rem/16);
    font-size: 1.125rem;
    border-bottom: 1px solid var(--color-darkgrey);
}

.rating-list__item .row--inline-block .col{
    font-size: 1.125rem;
}

.rating-list__item.no-border{
    border:none;
}

.widget__small-title.widget__small-title {
    font-size: calc(22rem/16);
    margin: calc(18rem/16) 0 calc(10rem/16) 0;
    color:var(--color-primary);
    font-family: var(--font-bold) !important;
}

@media screen and (max-width: 767px) {
    .widget__header {
        font-size: calc(20rem/16);
        padding: calc(14rem/16) calc(15rem/16) calc(10rem/16);
    }

    .rating-list__item {
        padding:calc(5rem/16) 0 calc(5rem/16);
        margin: 0;
        font-size: 1rem;
    }
    .widget .rating-list__item {
        padding: calc(5rem/16) calc(15rem/16) calc(5rem/16);
    }
    .rating-list__item:first-child{
        padding-top: 0;
    }
    .widget .rating-list__item:first-child {
        padding-top: calc(5rem/16);
    }

    .widget__body {
        padding: calc(15rem/16);
    }

    .rating-list__item label.w-100{
        width: 100%;
    }

}
.widget .rating-list__item:last-child {
    border-bottom: 0;
}

@media screen and (min-width: 768px) {
    .widget .storelocator-container-map{
        min-height: calc(365rem/16);
    }
}
