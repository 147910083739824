.rent-tipp {
    padding:calc(35rem/16) 0;
    background: url(img/bg/bg-mosaik.jpg) 50% 50% no-repeat;
    background-size:cover;
}
.content-block.content-block--last-no-margin:last-child .rent-tipp-container,
.pimcore_area_content:last-child > .content-block .rent-tipp {
    padding-bottom:calc(180rem/16);
}

.rent-tipp .section-headlines {
    color:#fff;
}
@media screen and (min-width:768px) {
    .rent-tipp {
        padding:calc(65rem/16) 0 calc(85rem/16) 0;
    }
    .content-block.content-block--last-no-margin:last-child .rent-tipp-container,
    .pimcore_area_content:last-child > .content-block .rent-tipp {
        padding-bottom:calc(280rem/16);
    }
}
.rent-tipp__decorator {
    position: relative;
    text-align: center;
    margin-bottom:1.25rem;
}
.rent-tipp__decorator .icon {
    position: relative;
    top:calc(6rem/16);
    display: inline-block;
    font-size:calc(60rem/16);
    line-height:1;
    z-index:10;
    background:#fff;
    color:var(--color-primary);
    border:1px solid #fff;
    border-width:0 calc(25rem/16);
}
.rent-tipp__decorator:after {
    content:'';
    z-index:5;
    position: absolute;
    left:0;
    right:0;
    top:50%;
    transform: translateY(-50%);
    border-bottom:1px solid var(--color-primary);
}


.rent-tipp__body {
    width: 100%;
    max-width:calc(830rem/16);
    margin:0 auto;
    padding:calc(10rem/16) calc(25rem/16);
    color:var(--color-default);
    background:#fff;
}

@media screen and (min-width:768px) and (max-width:1199px) {
    .rent-tipp {
        padding-top:calc(60rem/16);
        padding-bottom:calc(60rem/16);
    }
    .rent-tipp__body {
        padding:calc(30rem/16) calc(65rem/16);
        box-shadow: 0 22px 15px -20px rgba(0,0,0,0.5);
    }
}


@media screen and (min-width:1200px) {
    .rent-tipp {
        padding-top:calc(100rem/16);
        padding-bottom:calc(100rem/16);
    }
    .rent-tipp__body {
        padding:calc(45rem/16) calc(100rem/16);
        box-shadow: 0 22px 15px -20px rgba(0,0,0,0.5);
    }
    .rent-tipp__body .wysiwyg {
        font-size:1rem;
        line-height:1.5;
    }
    .rent-tipp__body .wysiwyg p,
    .rent-tipp__body .wysiwyg ul,
    .rent-tipp__body .wysiwyg ol {
        margin-bottom:1.25rem;
    }
}