.pre-footer {
    position: relative;
    min-height:calc(250rem/16);
    margin-top:calc(40rem/16);
    background: linear-gradient(to bottom, transparent 0%,transparent 50%,transparent 50%,#eae9e9 50%,#eae9e9 100%);
}
@media screen and (min-width: 768px) {
    .pre-footer {
        min-height:calc(300rem/16);
        font-size: 1rem;
        line-height: calc(26/16);
        margin-top:calc(100rem/16);
    }
}
.pre-footer__bg {
    position: relative;
    width: 100%;
    max-width:calc(280rem/16);
    box-shadow: 0 calc(5rem/16) calc(15rem/16) 0 rgba(0,32,90,0.25);
    margin:0 auto calc(35rem/16) auto;
    background-size: cover;
    background-position:50% 50%;
    background-repeat: no-repeat;
}
.pre-footer__bg:before {
    content:'';
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background-color: var(--color-primary-trans);
    mix-blend-mode: multiply;
    z-index:5;
}
.pre-footer__inner {
    position: relative;
    padding: calc(40rem/16) calc(25rem/16);
    color: #fff;
    font-size: .875rem;
    line-height: 1.5;
    z-index:10;
}
.pre-footer--secondary .pre-footer__bg:before {
    background-color: var(--color-secondary-trans);
}
.pre-footer__title {
    color: #fff;
    font-size: calc(23rem/16);
    font-family: var(--font-bold);
    line-height: calc(32/30);
    margin:0;
}
.pre-footer__txt{
    margin:calc(10rem/16) 0 calc(15rem/16) 0;
}
@media screen and (min-width: 768px) {
    .pre-footer__title {
        font-size: calc(30rem/16);
    }
    .pre-footer__txt{
        margin:calc(15rem/16) 0 calc(35rem/16) 0;
    }
    .pre-footer__bg {
        max-width:100%;
        margin:0 auto;
    }
    .pre-footer__inner {
        font-size: 1.125rem;
        padding: calc(70rem/16) calc(25rem/16);
    }
}