.parsley-errors-list.filled,
.form-errors.invalid-feedback {
    display: block;
    font-size:.875rem;
    margin-top:.25rem;
    list-style: none;
}
.parsley-errors-list,
.form-errors:empty,
.is-valid .form-errors {
    display: none;
}
.parsley-errors-list,
.parsley-errors-list li,
.form-errors ul,
.form-errors ul li{
    list-style-type: none;
    padding: 0;
    margin: 0;
    color:#dc3545;
}