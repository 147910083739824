.webcam-teaser {
    position: relative;
    border: 1px solid #E3E3E3;
    height:100%;
    transition: all 0.2s linear;
}
.webcam-teaser:hover,
.webcam-teaser:focus {
    transform: translateY(calc(-10rem/16));
}
.webcam-teaser__img {
    box-shadow: 0 22px 15px -20px rgba(0,0,0,0.5);
}
.webcam-teaser__img-embed {
    background-size:cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.webcam-teaser__body {
    line-height:1.25;
    padding:1.25rem;
}
.webcam-teaser__headline {
    font-size:1.25rem;
    margin:0;
    color:var(--color-primary);
    font-family: var(--font-bold);
}
@media screen and (min-width: 1200px) {
    .webcam-teaser__body {
        line-height:1.65;
        font-size:1rem;
        padding:calc(30rem/16);
    }
    .webcam-teaser__headline {
        font-size:calc(25rem/16);
    }
}