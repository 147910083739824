html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    background:#F7F7F7;
    overflow-x: hidden;
}
body.body__location {
    background:#fff;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
    }
}
.container.container {
    max-width:calc(1292rem / 16);
    width: 100%;
}
.container .container {
    padding-left:0;
    padding-right:0;
}
.container.container--narrow {
    max-width:calc(850rem / 16);
}
.container.container--extra-narrow {
    max-width:calc(450rem / 16);
}
body:not(.iframe) #main.main__shop {
    padding-top:calc(42rem / 16);

    @media (max-width: 767px) {
        padding-top:calc(16rem / 16);
    }
}

body.iframe #main {
    padding-top:calc(16rem / 16);
}
/** landing pages **/
body #main.main__content-landing {
    padding-top: 0;
}
body #main.main__content-landing .content-wrapper {
    min-height: 800px;
}

.portal-wrapper,
.content-wrapper {
    background-color: #fff;
    overflow: hidden;
}