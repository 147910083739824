.progressbar {
    position: relative;
    color:#A0A2A9;
    display: flex;
    justify-content: space-between;
    margin:0 auto calc(55rem/16) auto;
    width: 85%;
    @media screen and (min-width: 768px) and (max-width: 1199px){
        margin-bottom: calc(95rem/16);
    }
    @media screen and (min-width: 1200px){
        margin-bottom: calc(100rem/16);
    }
}
.progressbar--two-steps,
.progressbar--steps-n2 {
    @media screen and (min-width: 768px) {
        width: 50%;
    }
}
.progressbar--one-step,
.progressbar--steps-n1 {
    width: auto;
    justify-content: center;
}
.progressbar__item {
    display: inline-block;
    width: auto;
    font-family: var(--font-bold);
    position: relative;
    padding:0 2.5rem;
    background:#F7F7F7;

    @media screen and (max-width: 767px) {
        padding:0 1rem;
    }
}
.progressbar__item:before {
    content:'';
    display: block;
    width: 30vw;
    max-width:30rem;
    height:.125rem;
    background:#A0A2A9;
    position: absolute;
    left:100%;
    bottom:1.375rem;

    @media screen and (max-width: 767px) {
        width: 25vw;
    }
}
.progressbar--two-steps .progressbar__item:before,
.progressbar--steps-n2 .progressbar__item:before {
    @media screen and (max-width: 767px) {
        width: 50vw;
    }
}
.progressbar--four-steps .progressbar__item:before,
.progressbar--steps-n4 .progressbar__item:before {
    width: 20vw;
    @media screen and (max-width: 767px) {
        width: 20%;
    }
}
.progressbar__item:last-of-type:before {
    display: none;
}
.progressbar__circle {
    margin:0 auto .5rem auto;
    position: relative;
    display: block;
    width:2rem;
    height:2rem;
    line-height:2.25rem;
    border-radius: 100%;
    color:#fff;
    text-align: center;
    background:#A0A2A9;
}
.progressbar__icon {
    font-size:.625rem;
    position:absolute;
    left:50%;
    top:.75rem;
    transform: translateX(-50%);
    display: none;
    text-align: center;
}
.progressbar__txt {
    position: absolute;
    left:50%;
    top:3rem;
    transform: translateX(-50%);
    color:inherit;
    text-align: center;
    font-family: var(--font-bold);
    width:18rem;

    @media screen and (max-width: 767px) {
        font-size:.625rem;
        top:2.625rem;
        width:8rem;
        display: none;
    }
}


.progressbar__item--completed,
.progressbar__item--active {
    color:var(--color-primary);
}
.progressbar__item--completed:before {
    background: var(--color-primary);
}
.progressbar__item--completed .progressbar__icon {
    display: block;
}
.progressbar__item--completed .progressbar__circle {
    background:transparent;
    border:.125rem solid var(--color-primary);
    text-indent:-19999px;
}
.progressbar__item--active .progressbar__circle {
    background: var(--color-primary);
}
.progressbar--steps-n2 .progressbar__txt,
.progressbar__item--active .progressbar__txt {
    display: block;
}