.fullscreen-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // todo: change height to 100vw or so (for mobile iframe)
    z-index: 9999;
}
.fullscreen-modal__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0, 0.5);
}
.fullscreen-modal-header {
    position: sticky;
    top: 0;

    background-color: #fff;
    box-shadow: 0 5px 10px 0 rgba(51,51,51,0.1);
    font-size: calc(14rem/16);
    line-height: 1;
    text-align: center;
    font-family: var(--font-medium);

    z-index: 9;
    width: 100%;
    padding: calc(16rem/16);
    padding-right: calc(50rem/16);

    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(20rem/16);
    }
    @media screen and (max-width: 1760px) and (max-width: 768px) {
        padding: calc(19rem/16) calc(24rem/16);
    }
    @media screen and (max-width: 767px) {
        padding: calc(19rem/16) calc(20rem/16);
    }
}

.fullscreen-modal-header__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(45rem/16);
    padding: calc(14rem/16);
    border-left: 1px solid var(--color-lightgrey);
}
.fullscreen-modal__body {
    background: var(--color-lightgrey);
    padding: calc(24rem/16) calc(15rem/16);
    flex: 1;
    overflow-y: scroll;
}
.fullscreen-modal__body--has-error {
    margin-bottom: calc(50rem/16);
}
.fullscreen-modal__submit {
    grid-area: submit;
    @media (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
.fullscreen-modal__footer {
    background-color: #fff;
    z-index: 99;
    position: relative;
}

.fullscreen-modal__submit {
    width: 100%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    @media (min-width: 768px) {
        border-radius: 0;
    }
}
.fullscreen-modal__dialog {
    height: 100%;
    position: relative;
    width: 100%;
    margin: 0;
    @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
        max-width: calc(1040rem/16);
        height: 80vh;
    }
}
.fullscreen-modal__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: var(--color-lightgrey);
}
.fullscreen-modal__top-title {
    color: var(--color-secondary);
    letter-spacing: 0.5px;
    font-size: calc(14rem/16);
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
    }
}
.fullscreen-modal__title {
    color: var(--color-primary);
    font-family: var(--font-special);
    font-size: calc(40rem/16);
    line-height: 1.2;

    @media screen and (min-width: 768px) {
        /* font-size: clamp-calc(768px, 1920px, 30px, 48px); */
    }
}


/* iframe fixes */
.iframe .fullscreen-modal {
    align-items:normal;
    height:auto;
}
.iframe .fullscreen-modal__body {
    flex:none;
}
.iframe .fullscreen-modal__dialog {
    height:auto;
}
.iframe .fullscreen-modal__backdrop {
    height:100vh;
}
.iframe .fullscreen-modal__content {
    margin-top:3rem;
}