.main-search-container {
    @media screen and (min-width: 768px) {
        position: relative;
    }
}
.main-search-overlay {
    position: absolute;
    top: 50%;
    right: 0;
    background: #fff;
    opacity: 0;
    min-width:calc(320rem/16);
    transform: translateY(-200%);
    transition: 120ms ease;
    z-index: 5;

    @media screen and (max-width: 767px) {
        left:0;
        right:0;
        top:var(--navbar-mobile-height);
        padding:calc(10rem/16);
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    }
}
.main-search-overlay.is-visible {
    opacity: 1;
    transform: translateY(-50%);
    @media screen and (max-width: 767px) {
        transform: translateY(0);
    }
}
.main-search__input {
    border-radius: 0;
    height: calc(37rem/16);
    color:var(--color-primary);
    font-size:.875rem;
}
.btn.main-search__btn {
    padding: calc(5rem/16) calc(15rem/16);
    height: calc(37rem/16);
    border-radius: 0;
}
.main-search__btn-icon {
    vertical-align: -.18em;
}

.main-search__btn__close{
    position: relative;
    font-size: calc(25rem/16);
    display: block;
    top: calc(-4rem/16);
    @media screen and (min-width: 768px){
        top: calc(-8rem/16);
    }
}
