.rent-box {
    background: #fff;
    border-radius: .25rem;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
    padding:2rem;
    @media screen and (max-width: 767px) {
        padding:1.25rem;
    }
}

.bg-primary-gradient{
    background: #143ca0;
    background: linear-gradient(to bottom, #143ca0 0%, #133b9d 51%, #103183 100%);
    color: #fff;
}

.bg-info-gradient { background-image: linear-gradient(to top, transparent 0%, #f2f2f2 100%); }
.bg-grey{ background-color: var(--color-grey); }
.bg-darkgrey{ background-color: var(--color-darkgrey); }
.bg-season{ background-color: var(--color-bg-season); color: var(--color-bg-season-contrast); }

@media screen and (min-width: 768px){
    .container__break-left-half-sm {
        margin-left: 100%;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    .container__break-left-half-sm, .container__break-right-half-sm {
        width: calc(50vw - calc(15rem/16));
    }
}

@media screen and (max-width: 767px) {
    .container--no-padding-xs {
        padding: 0!important;
    }
}

.content-img {
    box-shadow: 0 22px 15px -20px rgba(0,0,0,0.5);
}

.w-auto{
    width: auto;
}

.pos-relative{
    position: relative;
}

[data-bs-toggle="tooltip"] {
    cursor: pointer;
}

.tooltip-toggler {
    position: relative;
    top:.125rem;
}


.text-icon{
    position: relative;
    padding-left:1.5rem;
}
.text-icon>.icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: calc(12rem/16);
    margin-left: calc(8rem/16);
    margin-top: calc(-1rem/16);
}
.text-icon > .text-icon__collapse-arrow {
    transition: transform 200ms ease-in-out;
    transform: translateY(-50%) rotate(-180deg);
}
.text-icon.collapsed > .text-icon__collapse-arrow {
    transform: translateY(-50%);
}
.icon-in-text {
    vertical-align: -.2em;
    margin-right:calc(10rem/16);
}
.icon-in-text-strong {
    vertical-align: -.1em;
    margin-right:calc(10rem/16);
}
.icon-rotate-45 {
    transform: rotate(45deg);
}
.underlined,
.text-underline {
    text-decoration: underline;
}
.whitespace-normal {
    white-space: normal;
}
.line-through {
    text-decoration: line-through;
}
.h-100{
    height: 100%;
}
@media screen and (min-width: 768px){
    .pull-right-sm,
    .pull-right--sm {
        float: right !important;
    }
    .pull-left--sm {
        float: left !important;
    }
}

@media screen and (max-width: 767px){
    .nav--xs-scrollable {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        padding:0 .75rem;
        margin-left: calc(-12rem/16);
        margin-right: calc(-12rem/16);
    }
    .nav--xs-scrollable::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    .nav--xs-scrollable::-webkit-scrollbar {
        width: .25rem;
        height: calc(5rem/16);
        background-color:transparent;
    }
    .nav--xs-scrollable::-webkit-scrollbar-thumb {
        background: #5d5d5d;
        border-radius: .25rem;
    }
    .nav--xs-scrollable > li {
        margin:0;
        white-space: nowrap;
        display: inline;
    }
}

.hr-dark{
    border-color: #ddd;
}

.circle{
    width: calc(30rem/16);
    height: calc(30rem/16);
    border-radius: 50%;
    border: .125rem solid;
}
.circle--bg {
    border-color: var(--color-info);
    background: var(--color-info);
}
.circle-toggle{
    display: inline-block;
    width: calc(19rem/16);
    height: calc(19rem/16);
    border: 1px solid var(--color-default);
    border-radius: 50%;
    font-size: .5rem;
    text-align: center;
    position: relative;
    color: var(--color-default);
}

.circle-toggle .icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.collapse-link .circle-toggle {
    top: .125rem;
}
.collapse-link.collapsed .icon:before {
    transform: rotate(0deg);
    display: block;
}
.collapse-link .icon:before {
    transform: rotate(180deg);
    display: block;
}

.collapse-link.collapsed .infos-less{
    display: none;
}

.collapse-link.collapsed .infos-more{
    display: block;
}

.collapse-link .infos-less{
    display: block;
}

.collapse-link .infos-more{
    display: none;
}

.collapse-link .collapse-info .icon {
    margin-left: calc(10rem/16);
    font-size: calc(11rem/16);
    position: relative;
    top: calc(1rem/16);
}

.hl-with-icon {
    font-size: calc(30rem/16);
    line-height:calc(48/30);
    position: relative;
    padding-left: calc(50rem/16);
    margin-bottom: 0;
}
.hl-with-icon>.circle {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(40rem/16);
    height: calc(40rem/16);
}
.hl-with-icon>.circle>.icon {
    font-size: calc(20rem/16);
}

.no-of-results {
    margin:calc(65rem/16) 0 calc(15rem/16) 0;
    text-align: center;
    border-top:1px solid #e3e3e3;
}
.no-of-results__number {
    position: relative;
    display: inline-block;
    padding:calc(10rem/16) calc(22rem/16);
    transform: translateY(-50%);
    border-top:1px solid transparent;
    background: #fff;
}

@media screen and (max-width: 767px){
    .no-of-results {
        margin:calc(40rem/16) 0 0 0;
    }
}
@media screen and (min-width: 768px){
    .results-number,
    .no-of-results__number {
        font-size: 1rem;
    }
}

/* old helper classes */
@media screen and (max-width: 767px){
    .hidden-xs {display: none}
}
.d-b{ display: block; }
.d-ib{ display: inline-block; }

.v-middle{ vertical-align: middle; }
.v-top{ vertical-align: top; }

/** Margins and Padddings **/
.m0{ margin: 0; }

.mb0{ margin-bottom: 0; }
.mb5{ margin-bottom: calc(5rem/16); }
.mb10{ margin-bottom: calc(10rem/16); }
.mb15{ margin-bottom: calc(15rem/16); }
.mb20{ margin-bottom: calc(20rem/16); }
.mb25{ margin-bottom: calc(25rem/16); }
.mb30{ margin-bottom: calc(30rem/16); }
.mb40{ margin-bottom: calc(40rem/16); }
.mb50{ margin-bottom: calc(25rem/16); }

.mt-auto {margin-top: auto;}
.mt0{ margin-top: 0; }
.mt5{ margin-top: calc(50rem/16); }
.mt10{ margin-top: calc(10rem/16); }
.mt15{ margin-top: calc(15rem/16); }
.mt20{ margin-top: calc(20rem/16); }
.mt30{ margin-top: calc(30rem/16); }
.mt40{ margin-top: calc(40rem/16); }
.mt50{ margin-top: calc(50rem/16); }
.mt60{ margin-top: calc(60rem/16); }

.mr5{ margin-right: calc(5rem/16); }
.mr10{ margin-right: calc(10rem/16); }
.mr20{ margin-right: calc(20rem/16); }

.ml5{ margin-left: calc(5rem/16); }
.ml10{ margin-left: calc(10rem/16); }
.ml20{ margin-left: calc(20rem/16); }
.ml-auto {margin-left: auto;}

.p0 { padding: 0; }

.pb0{
    padding-bottom: 0;
}
.pb10{
    padding-bottom: calc(10rem/16);
}
.pb20{
    padding-bottom: calc(20rem/16);
}


@media screen and (max-width: 767px) {
    .pb15-xs {
        padding-bottom: calc(15rem/16);
    }
    .pt0-xs { padding-top: 0; }

}

@media screen and (min-width: 768px){
    .mt30-sm{
        margin-top: calc(20rem/16);
    }

    .mb50{ margin-bottom: calc(50rem/16); }

}

.pt0 { padding-top: 0; }

.pt20{
    padding-top: calc(20rem/16);
}

.close--big {
    font-size: calc(40rem/16);
}


.close--medium {
    font-size: calc(30rem/16);
}

.col--border-left {
    border-left: 1px solid var(--color-darkgrey);
}
.w-100 {width: 100%;}
.flex-grow-1 { flex-grow: 1; }
.flex-grow-0 { flex-grow: 0; }
.order-last {order: 13;}

/** Workaround: Compatibility issues **/
.datepicker.input-icon .form-control.input-sm {
    padding-left: calc(40rem/16);
}
.shop-search .datepicker.input-icon .form-control--big {
    padding-left: calc(40rem/16);
}

.pos-relative.input-icon input.form-control {
    padding-left:calc(40rem/16);
}