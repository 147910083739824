.fact-teaser-container {
    padding:calc(35rem/16) 0;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.content-block.content-block--last-no-margin:last-child .fact-teaser-container,
.pimcore_area_content:last-child > .content-block .fact-teaser-container {
    padding-bottom:calc(180rem/16);
}

.fact-teaser {
    position: relative;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height:100%;
}
.fact-teaser__body {
    padding:calc(25rem/16) calc(15rem/16);
    color:#fff;
    text-align: center;
    height:100%;
    z-index:10;
}
.fact-teaser:before {
    content:'';
    display: block;
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background-color: var(--color-primary-trans);
    mix-blend-mode: multiply;
    z-index:5;
}
.fact-teaser--secondary:before {
    background-color: var(--color-secondary-trans);
}
.fact-teaser__heading {
    font-size:calc(20rem/16);
    margin:calc(10rem/16) 0;
    font-family: var(--font-bold);
}
.fact-teaser .icon {
    display: block;
    margin-bottom:calc(8rem/16);
    font-size:calc(30rem/16);
}
.fact-teaser .icon-ir-2021-rating {
    font-size:calc(45rem/16);
    margin:calc(-10rem/16) auto calc(-5rem/16) auto;
}

@media screen and (min-width:768px) {
    .fact-teaser-container {
        padding:calc(65rem/16) 0 calc(85rem/16) 0;
    }
    .content-block.content-block--last-no-margin:last-child .fact-teaser-container,
    .pimcore_area_content:last-child > .content-block .fact-teaser-container {
        padding-bottom:calc(180rem/16);
    }
    .fact-teaser__body {
        padding:calc(40rem/16) calc(25rem/16);
        font-size:1rem;
    }
    .fact-teaser--image .fact-teaser__inner {
        width:calc(50% - calc(25rem/16));
    }
    .fact-teaser__heading {
        font-size:calc(30rem/16);
    }
}

@media screen and (max-width:767px) {
    .fact-row + .fact-row {
        margin-top:calc(10rem/16);
    }
}
.fact-teaser__body {
    position: relative;
}
@media screen and (min-width:1200px) {
    .fact-teaser__body.fact-teaser__body {
        line-height:1.65;
        padding:calc(50rem/16);
    }
    .fact-teaser--image .fact-teaser__inner.fact-teaser__inner {
        width:calc(50% - calc(50rem/16));
    }
    .fact-teaser .icon {
        font-size:calc(60rem/16);
        margin-bottom:1.125rem;
    }
    .fact-teaser .icon-ir-2021-rating {
        font-size:calc(85rem/16);
        margin:calc(-10rem/16) auto calc(-8rem/16) auto;
    }
}