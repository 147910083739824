.fullwidth-teaser {
    position: relative;
    background: #fff;
    min-width:100%;
}
.fullwidth-teaser:before {
    content:'';
    width: 100%;
    height:auto;
    position: absolute;
    left:0;
    top:0;
    bottom:0;
    z-index:5;
    background: var(--color-primary-trans);
    mix-blend-mode: multiply;
}
.fullwidth-teaser--mirrored:before {
    left:auto;
    right:0;
}
.fullwidth-teaser--secondary:before {
    background: var(--color-secondary-trans);
}
.fullwidth-teaser__inner-container {
    position: relative;
    z-index:10;
}
.fullwidth-teaser__inner {
    position: relative;
    background-size: cover;
    background-position:50% 50%;
    background-repeat: no-repeat;
}
.fullwidth-teaser__body {
    line-height:1.25;
    color:#fff;
    padding:calc(28rem/16) calc(20rem/16);
}

.fullwidth-teaser__branding {
    margin-bottom: 1rem;
}

@media screen and (min-width: 768px){
    .slick-list .fullwidth-teaser__body {
        min-height:calc(700rem/16);
        padding:calc(150rem/16) 0 0 0;
    }
    .fullwidth-teaser__branding {
        margin-bottom: 1.5rem;
    }
}
.fullwidth-teaser__headline {
    font-size:1.25rem;
    margin:calc(10rem/16) 0 calc(15rem/16) 0;
    font-family: var(--font-bold);
}
.fullwidth-teaser__subline {
    font-size:1rem;
    margin:calc(10rem/16) 0 calc(15rem/16) 0;
}
.fullwidth-teaser__txt {
    margin:calc(10rem/16) 0 calc(15rem/16) 0;
    color:var(--color-text-default);
}

.fullwidth-teaser__author {
    position: relative;
    margin:0 0 calc(25rem/16) 0;
}
.fullwidth-teaser__author-img {
    width:calc(68rem/16);
    height:calc(68rem/16);
    border-radius: calc(100rem/16);
    overflow: hidden;
    border: .25rem solid rgba(255,255,255,0.2);
    box-shadow: 0 15px 30px -5px rgba(30,30,30,0.4);
}
.fullwidth-teaser__author-body {
    line-height:1.35;
    position: absolute;
    left:calc(90rem/16);
    top:50%;
    transform: translateY(-50%);
}
.fullwidth-teaser__ad-hint {
    color: #fff!important;
    font-size: .5rem;
    position: absolute;
    top: calc(15rem/16);
    left: calc(15rem/16);
    z-index: 5;
    opacity: 0.7;
}
.fullwidth-teaser--mirrored .fullwidth-teaser__ad-hint {
    right: calc(15rem/16);
    left: unset;
}
@media screen and (min-width: 768px){
    .fullwidth-teaser__author-body {
        font-size:1rem;
    }
    .fullwidth-teaser__ad-hint {
        font-size: .75rem;
    }
}
@media screen and (min-width: 768px) {
    .fullwidth-teaser:before {
        width: 50%;
    }
    .fullwidth-teaser__body {
        padding:calc(75rem/16) 0;
    }
    .fullwidth-teaser__headline {
        font-size:calc(25rem/16);
    }
    .fullwidth-teaser__subline {
        font-size:1.125rem;
    }
    .fullwidth-teaser {
        padding:calc(30rem/16);
    }
    .fullwidth-teaser__shadow{
        display: block;
        width: 66%;
        height:calc(150rem/16);
        position: absolute;
        right:calc(60rem/16);
        bottom:calc(55rem/16);
        transform: rotate(2deg);
        box-shadow: 0 50px 40px -10px rgba(0,0,0,0.45);
    }
    .fullwidth-teaser--mirrored .fullwidth-teaser__shadow {
        right:auto;
        left:calc(60rem/16);
        transform: rotate(-2deg);
    }
}
@media screen and (min-width: 1200px) {
    .fullwidth-teaser__body {
        line-height:1.65;
        font-size: 1rem;
        padding:calc(150rem/16) 0;
    }
    .fullwidth-teaser--usp .fullwidth-teaser__body {
        padding:calc(100rem/16) 0 calc(100rem/16) calc(20rem/16);
    }
    .fullwidth-teaser {
        padding:calc(50rem/16);
    }
    .fullwidth-teaser__headline {
        font-size:calc(35rem/16);
        margin:calc(5rem/16) 0 calc(15rem/16) 0;
    }
    .fullwidth-teaser__subline {
        margin:calc(5rem/16) 0 calc(15rem/16) 0;
    }
    .fullwidth-teaser__txt {
        margin:calc(15rem/16) 0 calc(25rem/16) 0;
    }
    .fullwidth-teaser__shadow{
        right:calc(80rem/16);
        bottom:calc(75rem/16);
    }
    .fullwidth-teaser--mirrored .fullwidth-teaser__shadow {
        right: auto;
        left:calc(80rem/16);
    }
}