@media screen and (min-width:768px) {
    .list-primary,
    .wysiwyg p,
    .wysiwyg ol,
    .wysiwyg ul{
        padding-left:0;
        margin-bottom:calc(25rem/16);
    }
}

.wysiwyg a:not(.btn) {
    color:var(--color-primary);
    text-decoration: underline;
}

.wysiwyg a:not(.btn):hover,
.wysiwyg a:not(.btn):focus {
    text-decoration:none;
}

/* listen */
.list-primary>li,
.wysiwyg ul>li {
    margin:.25rem;
    margin-left:1rem;
    padding-left:1rem;
    list-style: none;
    position: relative;
    @media screen and (max-width: 767px) {
        margin-left:0;
    }
}
.list-primary>li:before,
.wysiwyg ul>li:before {
    content:'';
    position: absolute;
    left:-.75rem;
    top:calc(7rem/16);;
    display: block;
    width:.375rem;
    height:.375rem;
    background: var(--color-primary);
}

.wysiwyg ol>li {
    margin:.25rem;
    margin-left:1rem;
    padding-left:1rem;
    @media screen and (max-width: 767px) {
        margin-left:0;
    }
}

.further-links a {
    display: inline-block;
    margin:.275rem .5rem .5rem 0;
}