
.rating__stars {
    position: relative;
    width: calc(130rem/16);
    display: inline-block;
    top: .25rem;
}

.rating__stars-inactive {
    background: url('../img/stern-grau.png') repeat-x;
    width: 100%;
    height: calc(20rem/16);
}

.rating__stars-active {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    background: url('../img/stern-gold.png') repeat-x;
    height: calc(20rem/16);
    max-width: 100%;
}

.rating__text {
    font-weight: bold;
    margin-left:calc(10rem/16);
    font-family: var(--font-default-bold);
    top: .125rem;
    position: relative;
}

.rating__text--light {
    font-weight: normal;
    font-family: var(--font-default);
}

@media screen and (max-width: 767px) {
    .rating__stars {
        width: calc(100rem/16);
    }

    .rating__stars-inactive,
    .rating__stars-active {
        background-size: calc(20rem/16);
        height: calc(15rem/16);
    }
}

.rating [type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

.rating{
    /*float: left;*/
}

.rating > input {
    display: none;
    overflow: visible;
}

.rating > label {
    color: #ddd;
    float: right;
}
.rating > label:before {
    margin: calc(5rem/16);
    font-family: iconfont;
    display: inline-block;
    content: var(--icon-star-empty);
}

.rating > input:checked ~ label,
.rating:not(:checked) > input:not(:disabled) + label:hover,
.rating:not(:checked) > input:not(:disabled) + label:hover ~ label {
    color: #ffce3b;
}

.rating > .half:before {
    position: absolute;
    content: var(--icon-star-full);
}