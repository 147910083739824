@media screen and (max-width: 767px) {
    .fancy-teaser-wrap {
        margin-left:calc(-10rem/16);
        margin-right:calc(-10rem/16);
    }
}


.fancy-teaser {
    position: relative;
    background: #fff;
}
.fancy-teaser__thumb {
    position: relative;
    pointer-events: none;
    left:8%;
    top: calc(-205rem/16);
    margin-bottom: calc(-205rem/16);
}
.fancy-teaser__thumb-img {
    box-shadow: 0 22px 15px -20px rgba(0,0,0,0.5);
}
.fancy-teaser:before {
    content:'';
    width: 100%;
    height:auto;
    position: absolute;
    right:0;
    top:0;
    bottom:0;
    z-index:5;
    background: var(--color-primary-trans);
    mix-blend-mode: multiply;
}
.fancy-teaser--secondary:before {
    background: var(--color-secondary-trans);
}
.container.fancy-teaser__inner-container {
    position: relative;
    z-index:10;

    @media screen and (max-width: 767px){
        padding:0 1rem;
    }
}
.fancy-teaser__inner {
    position: relative;
    background-size: cover;
    background-position:50% 50%;
    background-repeat: no-repeat;
}
.fancy-teaser__body {
    color:#fff;
    padding:calc(20rem/16) 0;
}
.fancy-teaser__headline {
    font-size:calc(20rem/16);
    margin:calc(10rem/16) 0 calc(15rem/16) 0;
    font-family: var(--font-bold);
}
.fancy-teaser__subline {
    font-size:1rem;
    margin:calc(10rem/16) 0 calc(15rem/16) 0;
}
.fancy-teaser__txt {
    margin:calc(10rem/16) 0 calc(15rem/16) 0;
    color:var(--color-text-default);
}
@media screen and (min-width: 768px) {
    .fancy-teaser:before {
        width: 58.33333%;
    }
    .fancy-teaser__inner {
        box-shadow: 0 22px 15px -20px rgba(0,0,0,0.5);
    }
    .fancy-teaser__body {
        padding:calc(75rem/16) 0;
    }
    .fancy-teaser__headline {
        font-size:calc(25rem/16);
    }
    .fancy-teaser__subline {
        font-size:1.125rem;
    }
    .fancy-teaser {
        padding:calc(30rem/16) 0;
    }
}
@media screen and (min-width: 1200px) {
    .fancy-teaser {
        padding:calc(50rem/16) 0;
    }
    .fancy-teaser__body {
        line-height:1.65;
        font-size: 1rem;
        width: calc(600rem/16);
        padding:calc(150rem/16) 0 calc(120rem/16) calc(35rem/16);
    }
    .fancy-teaser__inner {
        width:75%;
        box-shadow: 0 22px 15px -20px rgba(0,0,0,0.5);
    }
    .fancy-teaser__headline {
        font-size:calc(35rem/16);
        margin:calc(5rem/16) 0 calc(15rem/16) 0;
    }
    .fancy-teaser__subline {
        margin:calc(5rem/16) 0 calc(15rem/16) 0;
    }
    .fancy-teaser__txt {
        margin:calc(15rem/16) 0 calc(25rem/16) 0;
    }
    .fancy-teaser__shadow{
        right:calc(80rem/16);
        bottom:calc(75rem/16);
    }
}