.ratio-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ratio-map {
    --bs-aspect-ratio: 166%;
    @media (min-width: 768px) {
        --bs-aspect-ratio: 60%;
    }
}

.ratio-widget_iski  {
    --bs-aspect-ratio: 30%;
    @media (min-width: 1200px) {
        --bs-aspect-ratio: 30%;
    }
    @media (min-width: 1100px) and (max-width: 1199px) {
        --bs-aspect-ratio: 35%;
    }
    @media (min-width: 660px) and (max-width: 1099px) {
        --bs-aspect-ratio: 66%;
    }
    @media (min-width: 560px) and (max-width: 659px) {
        --bs-aspect-ratio: 80%;
    }
    @media (max-width: 559px) {
        --bs-aspect-ratio: 100%;
    }
}