.content-block + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px) and (max-width: 1199px){
        margin-top: calc(55rem/16);
    }
    @media screen and (min-width: 1200px){
        margin-top: calc(75rem/16);
    }
}

/*@media screen and (min-width: 768px) and (max-width: 1199px){
    .content-block--last,
    .pimcore_area_content:last-child > .content-block {
        margin-bottom: 250px;
    }
}

@media screen and (min-width: 1200px){
    .content-block--last,
    .pimcore_area_content:last-child > .content-block {
        margin-bottom: 250px;
    }
}*/

/* checkout */
.checkout-block {
    margin: calc(30rem/16) 0 calc(45rem/16) 0;
    @media screen and (min-width: 768px) and (max-width: 1199px){
        margin: calc(45rem/16) 0 calc(55rem/16) 0;
    }
    @media screen and (min-width: 1200px){
        margin: calc(55rem/16) 0 calc(70rem/16) 0;
    }

}