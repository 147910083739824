.alert {
    color:#000;
    border:0;
    margin-bottom: 0;
    padding:0;
    position: relative;
    background:transparent;
}
.alert a.btn-link {
    color:inherit;
    text-decoration:underline;
}
.alert + .alert {
    margin-top:1.375rem;
}
.alert-info,
.alert-warning,
.alert-danger,
.alert-success {
    padding:2rem 2.5rem;
    @media screen and (max-width: 767px) {
        padding:1.25rem 1.25rem;
    }
}
.alert--small .alert {
    font-size:calc(15rem / 16);
    padding:1.25rem;
}
.alert-dismissible .alert {
    @media screen and (max-width: 767px) {
        padding-right:2.5rem;
    }
}
.alert-info {
    background-color:var(--color-bg-info);
}
.alert-warning {
    background-color:var(--color-bg-warning);
}
.alert-danger {
    background-color:var(--color-bg-danger);
}
.alert-success {
    background-color:var(--color-bg-success);
}

.alert__headline {
    margin-bottom: .5rem;

    @media screen and (max-width: 767px) {
        margin-bottom: .375rem;
    }
}
.alert__icon {
    position:absolute;
    left:50%;
    transform: translateX(-50%);
    top:-1.25rem;
    display:block;
    width:2.5rem;
    height:2.5rem;
    line-height:2.5rem;
    font-size:1.5rem;
    border-radius: 100%;
    color:#fff;
    text-align: center;
    background:  #69B131;

    @media screen and (max-width: 767px) {
        width:2rem;
        height:2rem;
        line-height:2rem;
        font-size:1.125rem;
        top:-1rem;
    }
}