.shop-fact {
    border-radius: 0;
    margin-bottom: calc(15rem/16);
    border: 1px solid #E3E3E3;
}
@media screen and (min-width: 768px) {
    .shop-fact {
        justify-content: center;
        height: 100%;
    }
}

.shop-fact__content {
    padding:1.25rem;
    display: inline-block;
}

.shop-fact__icon {
    display: none;
}

.shop-fact__title {
    font-size:1.25rem;
    margin:0 0 calc(10rem/16) 0;
    color:var(--color-primary);
    font-family: var(--font-bold);
}
@media screen and (min-width:1200px) {
    .shop-fact__content {
        line-height:1.65;
        font-size:1rem;
        padding:calc(30rem/16);
    }
    .shop-fact__title {
        font-size:calc(25rem/16);
        margin:0 0 calc(15rem/16) 0;
    }
}
