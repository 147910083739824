@media screen and (min-width: 768px) and (max-width: 1400px){
    .hide-text{
        display: none;
    }
}
.header-seasonswitch {
    display: inline-block;
    margin-left:calc(20rem/16);
    border: 1px solid var(--color-primary);
    border-radius: calc(30rem/16);
    overflow: hidden;
    box-shadow: 0 5px 15px 0 rgba(0,32,90,0.125);
}
.header-seasonswitch__item {
    display: inline-block;
    padding:calc(12rem/16) calc(20rem/16);
    white-space: nowrap;
    line-height:1;
    text-align: center;
    color:var(--color-primary);
}
.header-seasonswitch__item .icon {
    position: relative;
    line-height:1px;
    font-size:calc(22rem/16);
    top:.375rem;
    margin-right:calc(3rem/16);
}
.header-seasonswitch__item .icon-ski {
    font-size:calc(17rem/16);
    top:.25rem;
}
.header-seasonswitch__item--ski {
    border-bottom-right-radius: 10rem;
    border-top-right-radius: 10rem;
}
.header-seasonswitch__item--bike {
    border-bottom-left-radius: 10rem;
    border-top-left-radius: 10rem;
}
.header-seasonswitch__item--ski.header-seasonswitch__item--active {
    margin-right:calc(-5rem/16);
}
.header-seasonswitch__item--bike.header-seasonswitch__item--active {
    margin-left:calc(-5rem/16);
}

.header-seasonswitch__item:hover,
.header-seasonswitch__item:focus,
.header-seasonswitch__item--active {
    color:#fff;
    background:var(--color-primary);
}
.header-seasonswitch__item--active:hover,
.header-seasonswitch__item--active:focus {
    color:#fff;
}




@media screen and (min-width: 1600px) {
    .header-seasonswitch {
        margin-left:calc(60rem/16);
    }
    .header-seasonswitch__item {
        padding:calc(12rem/16) calc(40rem/16);
    }
    .header-seasonswitch__item--ski.header-seasonswitch__item--active {
        margin-right:calc(-22rem/16);
    }
    .header-seasonswitch__item--bike.header-seasonswitch__item--active {
        margin-left:calc(-22rem/16);
    }
}