.sidebar {
    width:calc(297rem / 16);
    @media screen and (max-width: 767px) {
        width:100%;
    }
    @media (min-width: 768px) {
        position: sticky;
        top:1rem;
    }
}

/* cart */
.cart-sidebar__inner {
    position: relative;
    padding:1rem 1.25rem 0 1.25rem;
    border-radius:.25rem;
    background: #fff;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
}
.cart-sidebar__inner--empty {
    padding-bottom:1px;
}
.cart-sidebar__details {
    font-size:.875rem;
    color:var(--color-primary);
    font-family: var(--font-bold);
}


.cart-sidebar__total {
    padding:1rem 0;
    border-top:.125rem solid #F7F7F7;
}
.cart-sidebar__voucher {
    position: relative;
    width:auto;
    margin:0 -1.25rem;
    padding:1rem 0;
    border-top:.125rem solid #F7F7F7;
}
.cart-sidebar__item {
    font-size:calc(15rem / 16);
    line-height:1.25;
    margin-bottom: 1rem;
}
.cart-sidebar__total {
    font-family: var(--font-bold);
    font-size:calc(13rem / 16);
}
.cart-sidebar__total-price {
    line-height:1;
    font-size:calc(22rem / 16);
}
.cart-sidebar__btn {
    width:auto;
    margin:0 -1.25rem;
}