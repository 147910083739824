.footer{
    font-size: 16px;
    position: relative;
    margin-top: 1rem;
    z-index: 0;
}
/*.footer--with-pre-footer {
    margin-top:-150px;
}*/

.footer__bottom .container.container{
    max-width: 100%;
}

.footer__hl {
    font-size: 1.25rem;
    font-family: var(--font-light);
    line-height: calc(26/20);
    margin-bottom: .625rem;
}

.footer__links {
    line-height: 1.125;
}

.footer__links>li {
    margin:.5rem 0;
}

.footer__main {
    padding-top: calc(60rem/16);
    padding-bottom: calc(60rem/16);
    background-color: var(--color-darkgrey);

    @media screen and (max-width: 991px) {
        display: none;
    }
}






.footer__links a:not(.dropdown-item){
    color: var(--color-primary);
}

.footer__links a:not(.dropdown-item):hover,
.footer__links a:not(.dropdown-item):focus{
    color: var(--color-primary);
    text-decoration: underline;
}
.footer__bottom{
    color:#fff;
    background: var(--color-primary);
    padding-bottom: calc(30rem/16);
    padding-top: calc(30rem/16);
}
.footer__bottom .footer__links a,
.footer__bottom .footer__links a:hover,
.footer__bottom .footer__links a:focus{
    color:#fff;
}

@media screen and (max-width: 767px) {
    .footer__bottom{
        font-size:calc(14rem/16);
        padding-bottom:calc(25rem/16);
        padding-top:calc(25rem/16);
    }
}
.footer__bottom-middle-col {
    text-align:center;
}
@media screen and (min-width: 768px) and (max-width: 1199px){
    .footer__bottom-middle-col {
        text-align:right;
    }
}
@media screen and (max-width: 767px){
    .footer__bottom{
        text-align: center;
    }

    .footer__links{
        margin:calc(10rem/16) 0;
    }
}

.footer__bottom .footer__links.list-inline>li{
    padding: 0 1rem;
    margin: 0 !important;
    display: inline-block;

    @media screen and (max-width: 767px) {
        padding:.25rem .5rem;
    }
}

@media screen and (min-width: 768px) {
    .footer__claim {
        text-align:right;
    }
}
@media screen and (max-width: 767px) {
    .footer__bottom .footer__links.list-inline>li.dropup--footer-languages {
        display: block;
        margin-bottom:calc(10rem/16) !important;
    }
}