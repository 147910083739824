.branding-teaser {
    position: relative;
}
.branding-teaser__content{
    height: calc(616rem/16);
    position: relative;
}

.branding-teaser__content__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    position: relative;
    z-index: 3;
}
.branding-teaser__headline {
    font-size: 1.25rem;
    margin: calc(10rem/16) 0 calc(15rem/16) 0;
    font-family: var(--font-bold);
}
.branding-teaser__subline {
    font-size:1rem;
    margin:0 0 calc(15rem/16) 0;
}

.branding-teaser__content:before {
    content: "";
    background-color: var(--color-primary);
    position: absolute;
    height: calc(450rem/16);
    top: 50%;
    transform: translateY(-50%);
    left: calc(-24rem/16);
    right: calc(-30rem/16);
}
.branding-teaser__content.branding-teaser__content--secondary:before {
    background-color: var(--color-secondary);
}
.branding-teaser--left .branding-teaser__content:before {
    left: calc(-30rem/16);
    right: calc(-24rem/16);
}

.branding-teaser__branding {
    margin-bottom: 1rem;
}
.branding-teaser__btn {
    color: var(--color-primary);
    background-color: #fff;
    margin-top: 1.25rem;
    align-self: flex-start;
    font-family: var(--font-default);
    border: 1px solid transparent;
    transition: background-color 0.15s ease;
}
.branding-teaser__btn:hover {
    border-color: #fff;
    color: #fff;
    background-color: transparent;
}
.branding-teaser__ad-hint{
    font-size: .5rem;
    color: #ACACAC;
}
@media screen and (max-width: 767px){
    .branding-teaser__shadow {
        display: block;
        width: 66%;
        height:calc(150rem/16);
        position: absolute;
        z-index: 1;
        right: calc(5rem/16);
        bottom: calc(15rem/16);
        transform: rotate(2deg);
        box-shadow: 0 50px 40px -10px rgba(0,0,0,0.45);
    }
    .branding-teaser__image {
        z-index: 2;
    }
    .branding-teaser__content{
        background-color: var(--color-primary);
        height: auto;
        z-index: 0;
    }
    .branding-teaser__content--secondary{
        background-color: var(--color-secondary);
    }
    .branding-teaser__content:before{
        display: none;
    }
    .branding-teaser__content {
        margin: 0 calc(25rem/16);
        padding: calc(30rem/16);
    }
    .branding-teaser__ad-hint{
        text-align: center;
    }
}
@media screen and (min-width: 768px){
    .branding-teaser__branding {
        margin-bottom: 1.5rem;
    }
    .branding-teaser__btn {
        margin-top: calc(30rem/16);
    }
    .branding-teaser__shadow {
        display: block;
        width: 66%;
        height:calc(150rem/16);
        position: absolute;
        right: calc(40rem/16);
        bottom: calc(30rem/16);
        transform: rotate(2deg);
        box-shadow: 0 50px 40px -10px rgba(0,0,0,0.45);
    }
    .branding-teaser__shadow.branding-teaser__shadow--left {
        left: calc(40rem/16);
        right: unset;
        transform: rotate(-2deg);
    }
    .branding-teaser__ad-hint{
        font-size: calc(12rem/16);
        position: absolute;
        bottom: calc(40rem/16);
        left: -1.125rem;
    }
    .branding-teaser--left .branding-teaser__ad-hint{
        right: -1.125rem;
        left: unset;
    }
}
@media screen and (min-width: 768px) and (max-width: 1299px) {
    .branding-teaser__content{
        display: flex;
        justify-content: center;
        height: clamp(450px, 46vw, 600px);
    }
    .branding-teaser__content:before{
        height: clamp(300px,33vw,450px);
    }
    .branding-teaser__content__inner{
        padding: 2.5rem 0 2.5rem 2.5erm;
    }
    .branding-teaser--left .branding-teaser__content__inner{
        padding: 2.5rem 2.5rem 2.5rem 0;
    }
}
@media screen and (min-width: 1300px) {
    .branding-teaser--left .branding-teaser__content:before {
        left: calc( (100vw - 1279px) / -2 );
        right: calc(-24rem/16);
    }
    .branding-teaser__content:before {
        right: calc( (100vw - 1279px) / -2 );
        left: calc(-24rem/16);
    }
    .branding-teaser__content__inner {
        padding: calc(100rem/16) 0 calc(100rem/16) calc(100rem/16);
        height: calc(616rem/16);
    }
    .branding-teaser--left .branding-teaser__content__inner{
        padding: calc(100rem/16) calc(100rem/16) calc(100rem/16) 0;
    }
    .branding-teaser__headline {
        font-size: calc(45rem/16);
    }
    .branding-teaser__ad-hint{
        bottom: calc(54rem/16);
    }
}