.shop-teaser {
    text-align: center;
    background: #fff;
    font-size: calc(15rem/16);
    box-shadow: 0 calc(5rem/16) calc(10rem/16) 0 rgba(0,0,0,0.1);
}
.shop-teaser__col:not(.shop-teaser__col--image) {
    padding-top:1.5rem;
    padding-bottom: 1.5rem;
}

@media screen and (min-width: 768px) {
    .shop-teaser__col:not(.shop-teaser__col--image) + .shop-teaser__col {
        border-left:.125rem solid #F7F7F7;
    }
}