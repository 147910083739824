.topbar {
    position: relative;
    border-radius:.25rem;
    background: #fff;
    margin-bottom:2rem;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
    @media (min-width: 768px) {
        height:calc(45rem/16);
    }
}
.topbar__btn.topbar__btn {
    display: block;
    position: relative;
    text-align: left;
    width:100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: var(--font-medium);
    border: none;
    background: transparent;
    font-size: calc(14rem/16);
    padding: calc(10rem/16) calc(35rem/16);
    -webkit-appearance: none;
    @media screen and (min-width: 768px) {
        height:calc(45rem/16);
        font-size: calc(15rem/16);
        padding: calc(12rem/16) calc(35rem/16);
    }
}
.topbar__col + .topbar__col {
    @media screen and (max-width: 767px) {
        border-top:.125rem solid #F7F7F7;
    }
    @media screen and (min-width: 768px) {
       border-left:.125rem solid #F7F7F7;
    }
}
.topbar__btn .icon{
    font-size:133%;
    color:var(--color-primary);
    position: absolute;
    top: .75rem;
    left: calc(8rem/16);
}
.topbar__btn .topbar__btn-edit{
    font-size:110%;
    left:auto;
    right: calc(10rem/16);
}