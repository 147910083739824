.loading-spinner {
    margin-left: auto;
    margin-right: auto;
    width: calc(70rem/16);
    text-align: center;
}
.loading-spinner__item {
    width: 1.125rem;
    height: 1.125rem;
    background-color: var(--color-primary);

    border-radius: 100%;
    display: inline-block;
    animation: loading-spinner-rotate 1.4s infinite ease-in-out both;
}
.loading-spinner__item--1 {
    animation-delay: -0.32s;
}
.loading-spinner__item--2 {
    animation-delay: -0.16s;
}
@keyframes loading-spinner-rotate {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}


/* fullpage loading */
.loading-overlay--fullpage .loading-spinner-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}