.ratings-container {
    padding:calc(35rem/16) 0;
    /*background: url(../img/bg/bg-mosaik.jpg) 50% 50% no-repeat;*/
    background: var(--color-primary);
    background-size:cover;
}

.content-block.content-block--last-no-margin:last-child .ratings-container,
.pimcore_area_content:last-child > .content-block .ratings-container {
    padding-bottom:calc(180rem/16);
}
.ratings-container .section-headlines {
    color:#fff;
}
.rating-teaser {
    position: relative;
    box-shadow: 0 22px 15px -20px rgba(0,0,0,0.5);
}
.rating-teaser__body {
    font-size:calc(12rem/16);
    line-height:1.25;
    padding:calc(20rem/16);
    background: #fff;
}
.rating-teaser__review {
    font-size:1rem;
    margin:calc(15rem/16) 0;
    font-family: var(--font-default);
}
.rating-teaser .rating__text {
    font-weight: normal;
    margin-left:calc(10rem/16);
    font-family: var(--font-default);
    top: .125rem;
    position: relative;
}
.rating-teaser__meta {
    font-size: calc(14rem/16);
}
@media screen and (min-width:768px) {
    .ratings-container {
        padding:calc(65rem/16) 0 calc(85rem/16) 0;
    }
    .content-block.content-block--last-no-margin:last-child .ratings-container,
    .pimcore_area_content:last-child > .content-block .ratings-container {
        padding-bottom:calc(280rem/16);
    }
}
@media screen and (min-width:1200px) {
    .ratings-container.ratings-container {
        padding:calc(130rem/16) 0;
    }
    .rating-teaser__body {
        line-height:1.65;
        padding:calc(30rem/16);
    }
    .rating-teaser__review {
        font-size:1.25rem;
        margin:calc(15rem/16) 0 calc(30rem/16) 0;
    }
}




/** rating-teaser hero **/
.rating-teaser--hero  {
    box-shadow: none;
    padding:0;
    @media screen and (min-width:768px) {
        padding:0 1rem;
    }
}
.rating-teaser--hero .rating-teaser__body {
    padding:0;
}
.rating-teaser--hero .rating__text,
.rating-teaser--hero .rating-teaser__meta {
    font-family: var(--font-bold);
}
.rating-teaser--hero .country-flag {
    display: inline-block;
}
.rating-teaser--hero .rating-teaser__review {
    margin:calc(10rem/16) 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: .9375rem;
}