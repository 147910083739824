.ui-datepicker {
    border: 0;
    box-shadow: 0 0 30px 0 rgba(0,0,0,.15);
    background-color: #fff;
    z-index:9999999999 !important; /* z-index must be higher than chatwidget AND higher than modal */

    @media screen and (min-width: 768px) {
        width:350px;
    }
}
.ui-datepicker .ui-state-disabled {
    opacity: .33;
}
.ui-datepicker table .ui-state-hover,
.ui-datepicker table .ui-state-active {
    border-radius: .25rem;
}

.ui-datepicker .ui-datepicker-title select {
    padding-top:0;
    padding-left:0;
    padding-bottom:.25rem;
    border:0;
    height: auto;
    font-size:inherit;
    color:inherit;
    font-family: inherit;
    box-shadow: none;
    border-bottom:1px solid;
    width:33% !important;
    margin:.25rem 1rem;

    @media screen and (max-width: 767px) {
        font-size:.875rem;
    }
}

#modalReseration_change_interval .modal-edit-item #ui-datepicker-div {
    position: absolute !important;
    left: 0 !important;
    top: 4.875rem !important;
}

.date-input #ui-datepicker-div {
    width:100%;
    max-width:95vw;
    position: absolute !important;
    left: .125rem !important;
    top: 3.125rem !important;
}