.infobar {
    position: relative;
    border-radius:.25rem;
    padding:1.375em;
    background: #fff;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
}
.infobar__item {
    display: block;
    margin:.375rem 0 0 0;
    position: relative;
    width:100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: var(--font-medium);
    -webkit-appearance: none;
    border:0;
    color:inherit;
    padding:0;
    background: transparent;
}
.infobar__item .icon {
    margin-right:.375rem;
    vertical-align: -.1em;
}
.infobar__item + .infobar__item {
    @media screen and (max-width: 767px) {
        border-top:calc(1rem/16) solid #E3E3E3;
    }
}