.nav-pills {
    border: 0;
    border-bottom:.25rem solid var(--color-primary);
    .nav-link {
        background:#fff;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #E3E3E3;
        border-bottom:0;
        line-height: 1;

        @media screen and (min-width: 768px) {
            min-width:calc(210rem/16);
        }
    }
    .nav-link:focus,
    .nav-link:hover,
    .nav-link:active {
        background:var(--color-primary);
        border-color:var(--color-primary);
        color:#fff;
    }
}
