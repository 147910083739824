
.icon-form-control {
    position: relative;
}

.icon-form-control input,
.icon-form-control select {
    padding-left: calc(46rem/16);
}

.icon-form-control__icon {
    position: absolute;
    left: calc(20rem/16);
    top: 50%;
    transform: translateY(-50%);

    font-size: calc(17rem/16);
    color: var(--color-grey-dark);
    z-index: 2;
}

/* move to other file */
.booking-widget__form-control {
    font-size: calc(16rem/16);
    font-family: var(--font-default-medium);
    z-index: 1;
    position: relative;
}

/* booking widget */
.form-control--fake {
    min-height: calc(50rem/16);
    box-shadow: none;
}

.form-control__arrow {
    position: absolute;
    top: 0;
    left: 100%;
    width: 50px;
    height: 100%;
    overflow: hidden;
    @media (max-width: 767px) {
        display: none;
    }
}

.form-control__arrow:before {
    content: '';
    position: absolute;
    top: 31px;
    left: -17px;
    z-index: 1;
    background: white;
    width: 35px;
    height: 35px;
    box-shadow: 0 0 50px 0 rgba(51,51,51,0.3);
    transform: rotate(45deg) translate(-50%,-50%);
    transform-origin: center center;
    border: 1px solid transparent;
}

.input-group .form-control--fake + .form-control--fake {
    @media (min-width: 768px) {
        border-left: none;
        padding-left: 40px;
    }
    @media (min-width: 1400px) {
        padding-left: 50px;
    }
}
.form-control--fake.form-control--fake-disabled {
    background: var(--color-lightgrey);
}

.form-control--fake-disabled .form-control__arrow:before {
    background: var(--color-lightgrey);
    border: 1px solid white;
}
