.square-teaser {
    position: relative;
    color:#fff;
    background: var(--color-primary);
    transition: box-shadow 0.2s linear;
}
.square-teaser--magazin-wide {
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}
.square-teaser.square-teaser--magazin:hover {
    box-shadow: 0 22px 15px -20px rgba(0,0,0,0.5);
}
.square-teaser:before {
    content:'';
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    width: auto;
    height: auto;
    background: linear-gradient(180deg, rgba(22,65,148,0.7) 0%, rgba(22,43,108,0) 39.64%, rgba(22,65,148,0.8) 99.99%);
    z-index:3;
}
.square-teaser__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    font-size:calc(15rem/16);
    line-height:1.25;
    padding:1.25rem;
    position: absolute;
    height:auto;
    left:0;
    right: 0;
    bottom:0;
    top:0;
    z-index:4;
}
.square-teaser__main-headline {
    font-size:1.25rem;
    margin-bottom: calc(5rem/16);
    font-family: var(--font-bold);
}

.square-teaser__ad-hint{
    color: #fff!important;
    font-size: calc(8rem/16);
    position: absolute;
    bottom: calc(7rem/16);
    left: calc(15rem/16);
    z-index: 5;
    opacity: 0.7;
}
@media screen and (min-width: 768px){
    .square-teaser__ad-hint {
        font-size: calc(12rem/16);
    }
}
/* hide stuff on small mobile devices */
@media screen and (max-width: 360px) {
    .square-teaser__label,
    .square-teaser__subline,
    .square-teaser__txt {
        display: none;
    }
}

@media screen and (min-width: 1199px) {
    .square-teaser__body {
        line-height:1.65;
        padding:calc(40rem/16);
    }
    .square-teaser__label {
        left:calc(40rem/16);
        top:calc(35rem/16);
    }
    .square-teaser--magazin-text .square-teaser__main-headline {
        font-size:calc(35rem/16);
    }
}
@media screen and (min-width: 1440px) {
    .square-teaser__main-headline {
        font-size:calc(25rem/16);
    }
    .square-teaser__body {
        font-size:calc(16rem/16);
    }
}
.square-teaser__label {
    font-size:calc(14rem/16);
    position: absolute;
    left:calc(20rem/16);
    top:calc(20rem/16);
    right:auto;
    bottom:auto;
    width: auto;
    height: auto;
    z-index:5;
}
.square-teaser__txt {
    display: none;
    margin:calc(20rem/16) 0;
}
.square-teaser__btn {
    @media (hover: hover) and (pointer: fine) and (min-width:768px) {
        display: none;
    }
    @media (max-width:767px) {
        height: 0;
        overflow: hidden;
    }
}

/* fancy hover */
@media (hover: hover) and (pointer: fine) and (min-width:768px) {
    .square-teaser:not(.square-teaser--magazin):hover {
        z-index:10;
        margin:calc(-22rem/16) calc(-30rem/16) calc(-38rem/16) calc(-30rem/16);
        width:calc(100% + calc(60rem/16));
        box-shadow: 0 22px 15px -20px rgba(0,0,0,0.5);
    }
    .square-teaser:hover .square-teaser__txt,
    .square-teaser:hover .square-teaser__btn {
        display: block;
    }
    .square-teaser:hover:before,
    .square-teaser:focus:before {
        background: var(--color-primary-trans);
        mix-blend-mode: multiply;
    }
    .square-teaser:hover .img-copyright--right-bottom,
    .square-teaser:focus .img-copyright--right-bottom {
        right: 2.375rem;
        bottom: 2.875rem;
    }
}