/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-underline {
    text-decoration: underline;
}

/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default,.text-default { color: var(--color-default); }


/* Background Color Helper */
.bg-lightgrey{ background-color: #f9f9f9; }


/* Additional */
.rounded-image {
    overflow: hidden;
    border-radius: .25rem;
}
.pointer-events-none {
    pointer-events: none;
}

.cursor-pointer {
    cursor: pointer;
}

.not-active {
    pointer-events: none;
    opacity: .5;
}

.v-hidden {
    visibility: hidden;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}


.rounded-icon {
    display: inline-block;
    width:1.25rem;
    height:1.25rem;
    line-height:1.25rem;
    border-radius: 100%;
    color:#fff;
    text-align: center;
}
.rounded-icon > .icon {
    font-size:.5rem;
}

.fz16 {font-size:calc(16rem / 16);}
.fz15 {font-size:calc(15rem / 16);}
.fz14 {font-size:calc(14rem / 16);}
.fz13 {font-size:calc(13rem / 16);}
.fz12 {font-size:calc(12rem / 16);}
.fz11 {font-size:calc(11rem / 16);}
.fz10 {font-size:calc(10rem / 16);}