.hero {
    position: relative;
}
.hero--portal {
    margin-bottom: calc(25rem/16);
}
@media screen and (min-width: 768px){
    .hero--portal { 
        margin-bottom: calc(45rem/16);
    }
}
.hero__img {
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height:calc(280rem/16);
}
.hero__img--fh {
    height:90vh;
}
.hero-noimg {
    margin:calc(20rem/16) 0 calc(10rem/16) 0;
}

@media screen and (min-width: 768px) {
    .hero-noimg {
        margin:calc(60rem/16) 0;
    }
    .hero__img {
        height:calc(680rem/16);
    }
    .hero__img--fh {
        min-height:calc(750rem/16);
        height:80vh;
    }
}
.hero__img:before {
    content:'';
    width: 100%;
    position: absolute;
    left:0;
    top:0;
    bottom:0;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
}

.hero--portal .hero__img:before,
.hero--article .hero__img:before {
    background: linear-gradient(90deg, #FFFFFF 30%, rgba(255,255,255,0) 70%);
}

@media screen and (min-width: 768px){
    width: 85%;
}

.hero__back {
    position: absolute;
    left:0;
    top:1.25rem;
    right:0;
    color:var(--color-primary);
}
.hero-noimg__back {
    position: relative;
    margin-bottom:1.25rem;
}

@media screen and (min-width: 768px) {
    .hero-noimg__back {
        margin-bottom: calc(40rem/16);
        margin-top:calc(-40rem/16);
    }
}
.hero__back-link {
    color:var(--color-primary);
}

.hero__overlay {
    position: absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);
    right:0;
    color:var(--color-primary);
    z-index:9;
}
.search-widget-step-2-is-active .hero__overlay {
    z-index:99;
}
.hero__headline {
    margin:0;
    font-family: var(--font-bold);
    font-size:calc(30rem/16);
    color:var(--color-primary);
}
.hero__subline {
    margin:0;
    font-size:1rem;
    color:var(--color-primary);
}
.hero__usp {
    text-align: center;
    margin:calc(20rem/16) calc(-15rem/16) 0 calc(-15rem/16);
}
@media screen and (max-width: 767px) {
    .hero__usp {
        display: flex;
        justify-content: center;
        align-items: start;
    }
}
.hero__usp-item {
    display: inline-block;
    width: 25%;
    margin:calc(15rem/16) calc(5rem/16);
    position: relative;
    font-size: calc(14rem/16);
    line-height:1.25;
    color:var(--color-primary);
    font-family: var(--font-bold);
}
.hero__usp-item-circle {
    position: relative;
    margin: 0 auto calc(10rem/16) auto;
    display: block;
    width:calc(60rem/16);
    height:calc(60rem/16);
    border-radius: 100%;
    border:1px solid var(--color-primary);
}
.hero__usp-item .icon {
    position: absolute;
    left:50%;
    top:50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: calc(25rem/16);
}


.hero__slide{
    position: relative;
}

@media screen and (min-width: 768px) {
    .hero__headline {
        font-size:calc(40rem/16);
    }
    .hero__subline {
        font-size:calc(20rem/16);
    }
    .hero__usp {
        text-align: left;
        margin:calc(50rem/16) 0 0 0;
    }
    .hero__usp-item {
        width: auto;
        max-width:calc(250rem/16);
        padding-left:calc(100rem/16);
        margin:0 calc(30rem/16) 0 0;
        font-size: calc(20rem/16);
        line-height:1.25;
    }
    .hero__usp-item-circle {
        position: absolute;
        left:0;
        top:calc(-15rem/16);
        width:calc(80rem/16);
        height:calc(80rem/16);
        margin: 0;
    }
    .hero__usp-item .icon {
        font-size: calc(40rem/16);
    }
    .hero--slider {
        position: relative;
    }
    .hero__slider {
        position: relative;
        right: 0;
    }
    .hero__slide img {
        margin-left: auto;
    }
    .hero--slider::after {
        position: absolute;
        content: "";
        inset: 0;
        background: linear-gradient(to right,#fff 42%,transparent 70%);
    }
    .hero--portal .hero__headline {
        max-width: 45vw;
    }
    .hero--portal .hero--slider .hero__headline,
    .hero--blog .hero__headline {
        max-width: 40vw;
    }
    .hero--article .hero__img:before {
        background: linear-gradient(to right, #FFFFFF 42%, transparent 70%);
        max-width: calc(1600rem/16);
        right: 0;
        left: unset;
    }
    .hero--article .hero__img {
        background-position: right;
        background-size: contain;
    }
}

@media screen and (max-width: 767px) {
    .hero--slider .hero__overlay {
        position: relative;
        transform: none;
        padding-top: 1.5rem;
    }
    .hero--article .hero__back,
    .hero--article .hero__overlay {
        position: relative;
        inset: 0;
        transform: none;
    }
    .hero--article .hero__img:before {
        background: transparent;
    }
    .hero__content{
        margin-top: 1.5rem;
    }
    .hero--article .hero__back {
        margin-bottom: 1rem;
    }
}

@media screen and (min-width: 1200px) {
    .hero__headline {
        font-size:calc(55rem/16);
    }
    .hero--portal .hero__headline {
        font-size:calc(60rem/16);
    }
}

