.header-usp {
    color:#fff;
    font-size: calc(14rem/16);
    line-height:1;
    height:calc(50rem/16);
    padding: 0 calc(15rem/16);
    overflow: hidden;
    background: var(--color-primary);
}
.header-usp .icon-info {
    color:#fff;
    position: relative;
    margin-left:calc(5rem/16);
    top:calc(2rem/16);
    display: inline;
}
.header-usp__list {
    margin:0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.header-usp__list>li.list-inline-item.list-inline-item{
    position: relative;
    margin:0 calc(30rem/16) 0 0;
    padding-left:calc(33rem/16);
    display:flex;
    height:calc(50rem/16);
    flex-direction: column;
    justify-content: center;
}
.header-usp__list>li .small {
    display: block;
    text-align:center;
    font-size:calc(10rem/16);
    padding-top:calc(1rem/16);
}
@media screen and (min-width: 1280px){
    .header-usp {
        padding: 0 calc(30rem/16);
    }
}
@media screen and (min-width: 1440px){
    .header-usp__list>li.list-inline-item.list-inline-item {
        margin-right:calc(50rem/16);
        margin-left:calc(15rem/16);
    }
}
.header-usp__list>li:last-child {
    margin-right:0;
}
.header-usp__list>li:before,
.header-usp__list>li:after {
    content:'';
    position: absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);
}
.header-usp__list>li:before {
    display: block;
    width:calc(23rem/16);
    height:calc(23rem/16);
    background: #fff;
    border-radius: 50%;
}
.header-usp__list>li:after {
    left:calc(8rem/16);
    display: block;
    width:calc(6rem/16);
    height:calc(12rem/16);
    margin-top:calc(-7rem/16);
    transform: rotate(45deg);
    border-right:calc(2rem/16) solid var(--color-primary);
    border-bottom:calc(2rem/16) solid var(--color-primary);
}