@font-face {
    font-family: 'DINWebPro';
    src: url('fonts/DINWebPro.woff2') format('woff2'),
    url('fonts/DINWebPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINWebPro-Light';
    src: url('fonts/DINWebPro-Light.woff2') format('woff2'),
    url('fonts/DINWebPro-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINWebPro-Medium';
    src: url('fonts/DINWebPro-Medium.woff2') format('woff2'),
    url('fonts/DINWebPro-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINWebPro-Bold';
    src: url('fonts/DINWebPro-Bold.woff2') format('woff2'),
    url('fonts/DINWebPro-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

