.slider:not(.slick-slider)>div+div{
    display: none;
}
.slick-slider {
    line-height:0;
}
.slick-list {
    @media screen and (min-width: 768px){
        margin:0 -1rem;
    }
}

.content-slider {
    box-shadow: none !important;
}

.content-slider .slider__img {
    @media screen and (min-width: 768px){
        margin: 0 1rem;
    }
}

/*slider with shadow*/
.slider--shadow {
    box-shadow: 0 22px 15px -20px rgba(0,0,0,.5);
}

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: calc(26rem/16);
    height: calc(52rem/16);
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: var(--color-primary);
    z-index: 1;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    outline: none;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    display: block;
    font-family: iconfont;
    line-height: 1;
    content: var(--icon-arrow-right);
    text-indent: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    line-height: calc(52rem/16);
    text-align: center;
    color: #fff;
    font-size: 1rem;
}

.slick-prev
{
    left: 0;
    border-radius: 0 calc(26rem/16) calc(26rem/16) 0;
}
[dir='rtl'] .slick-prev
{
    right: 0;
    left: auto;
}
.slick-prev:before
{
    content: var(--icon-arrow-right);
    transform: scale(-1,1);
}
[dir='rtl'] .slick-prev:before
{
    content: var(--icon-arrow-right);
}

.slick-next
{
    right: 0;
    border-radius: calc(26rem/16) 0 0 calc(26rem/16);
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: 0;
}
.slick-next:before
{
    content: var(--icon-arrow-right);
}
[dir='rtl'] .slick-next:before
{
    transform: scale(-1,1);
}

/* white arrow buttons */
.slider--white-arrows .slick-prev,
.slider--white-arrows .slick-next {
    border-radius: 0;
    width: calc(42rem/16);
    height: calc(47rem/16);
    background: white;
}

.slider--white-arrows .slick-prev:before,
.slider--white-arrows .slick-next:before {
    font-size: 1.5rem;
    color: var(--color-primary-dark);
}

.slider--white-arrows .slick-next {
    right: 0;
}

.slider--white-arrows .slick-prev {
    left: 0;
}

@media screen and (min-width: 1200px){
    .slider--white-arrows .slick-next {
        right: calc(-20 - 42px);
    }
    .slider--white-arrows .slick-prev {
        left: calc(-20 - 42px);
    }
}




/* IR2021 arrow buttons */
.slider--ir2021 .slick-prev,
.slider--ir2021 .slick-next {
    border-radius: 0;
    width: calc(40rem/16);
    height: calc(40rem/16);
    background: white;
    border: 1px solid #DCDDDE;
}

.slider--ir2021 .slick-prev:before,
.slider--ir2021 .slick-next:before {
    position: absolute;
    left:50%;
    top:50%;
    right: auto;
    bottom: auto;
    transform: translateY(-50%) translateX(-50%);
    font-size: 1.5rem;
    line-height:1;
    margin:0;
    color: var(--color-primary);
    content: var(--icon-ir-2021-arrow-right);
}
.slider--ir2021 .slick-prev:before {
    transform: scaleX(-1) translateY(-50%) translateX(-50%);
    right:50%;
    top:50%;
    left: auto;
}

.slider--ir2021 .slick-next {
    right: 0;
}

.slider--ir2021 .slick-prev {
    left: 0;
}

@media screen and (min-width: 1200px){
    .slider--ir2021 .slick-next,
    .slider--ir2021 .slick-prev {
        width:calc(70rem/16);
        height:calc(70rem/16);
    }
    .slider--ir2021 .slick-prev:before,
    .slider--ir2021 .slick-next:before {
        font-size:calc(30rem/16);
    }
}

.slider--bottom-center .slick-next,
.slider--bottom-center .slick-prev {
    left:50%;
    top:auto;
    bottom:0;
    transform: none;
}
.slider--bottom-center .slick-prev {
    margin-left:calc(-40rem/16);
}
@media screen and (min-width: 768px){
    .magazin-slider .slick-next,
    .magazin-slider .slick-prev {
        bottom:calc(70rem/16);
    }
}

@media screen and (min-width: 1200px){
    .slider--bottom-center .slick-prev {
        margin-left:calc(-70rem/16);
    }
    .magazin-slider .slick-next,
    .magazin-slider .slick-prev {
        bottom:calc(90rem/16);
    }
}

.slider--bottom-right .slick-next,
.slider--bottom-right .slick-prev {
    left:auto;
    top:auto;
    bottom:0;
    right:0;
    transform: none;
}
.slider--bottom-right .slick-prev {
    right:calc(70rem/16);
}

@media screen and (max-width: 767px){
    .slider--bottom-right .slick-next,
    .slider--bottom-right .slick-prev,
    .slider--xs-bottom-right .slick-next,
    .slider--xs-bottom-right .slick-prev {
        left:auto;
        top:auto;
        bottom:0;
        right:0;
        transform: none;
    }
    .slider--bottom-right .slick-prev,
    .slider--xs-bottom-right .slick-prev {
        right:calc(40rem/16);
    }
}

/* hero */
.slider--ir2021.slider--hero {
    overflow: hidden;
}
.slider--ir2021.slider--hero .slick-next,
.slider--ir2021.slider--hero .slick-prev {
    left:auto;
    top:50%;
    bottom:auto;
    right:0;
    transform: translateX(0) translateY(-50%);
    background: transparent;
    border:0;
    color:#fff;
}
.slider--ir2021.slider--hero .slick-next:before,
.slider--ir2021.slider--hero .slick-prev:before {
    color:#fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, .75);
}
.slider--ir2021.slider--hero .slick-prev {
    right:auto;
    left:0;
}
.slider--ir2021.slider--hero .slick-dots {
    display:none !important;
}
/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: calc(30rem/16);
}

.slider--hide-dots .slick-dots {
    display:none !important;
}
.slick-dots {
    list-style-type: none;
    padding-left: 0;
    text-align: center;
    margin: 0;
}
.slider--left-dots .slick-dots {
    text-align: left;
    position: absolute;
    margin-top:.5rem;
}
.slider--bottom-dots .slick-dots {
    position: absolute;
    left:0;
    right:0;
    bottom:-2.5rem;
    @media screen and (max-width: 767px){
        bottom:-2rem;
    }
}
.slick-dots li {
    display: inline-block;
    margin-left: calc(2rem/16);
    margin-right: calc(3rem/16);
    padding: calc(2rem/16);
    border-radius: 50%;
    line-height: 0;
}
.slick-dots li button
{
    border-radius: 50%;
    width: calc(13rem/16);
    height: calc(13rem/16);
    background-color: #fff;
    text-indent: -999px;
    overflow: hidden;
    padding: 0;
    border: var(--color-primary) 2px solid;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}

.slick-dots > .slick-active {
    /*border: 1px solid var(--color-primary);*/
}
.slick-dots > .slick-active > button {
    background-color: var(--color-primary);
}

/*custom styles*/
@media screen and (max-width: 767px) {
    .slider-padding-xs {
        padding-left: calc(20rem/16);
        padding-right: calc(20rem/16);
    }
    .slider-padding-xs .slick-prev {
        left: 0;
    }
    .slider-padding-xs .slick-next {
        right:0;
    }

    .slick-prev{
        left: calc(10rem/16);
    }

    .slick-next{
        right: calc(10rem/16);
    }

}
.slider-thumbs--product__item__badge {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    max-width: calc(115rem/16);
}



/* ratings-slider */

.slider--small-left-bottom {
    position: relative;
    padding-bottom: 4rem;
}
.slider--small-left-bottom .slick-prev,
.slider--small-left-bottom .slick-next {
    position: absolute;
    left:0;
    top:auto;
    right: auto;
    bottom: 0;
    border-radius: 0;
    width: calc(36rem/16);
    height: calc(36rem/16);
    background: white;
    border: 1px solid #DCDDDE;
}
.slider--small-left-bottom .slick-prev:hover,
.slider--small-left-bottom .slick-prev:focus,
.slider--small-left-bottom .slick-next:hover,
.slider--small-left-bottom .slick-next:focus {
    background:var(--color-primary);
}
.slider--small-left-bottom .slick-next {
    left:calc(35rem/16);
}
.slider--small-left-bottom .slick-prev:before,
.slider--small-left-bottom .slick-next:before {
    position: absolute;
    left:50%;
    top:50%;
    right: auto;
    bottom: auto;
    transform: translateY(-50%) translateX(-50%);
    font-size: 1.125rem;
    line-height:1;
    margin:0;
    color: var(--color-primary);
    content: var(--icon-ir-2021-arrow-right);
}
.slider--small-left-bottom .slick-prev:before {
    transform: scaleX(-1) translateY(-50%) translateX(-50%);
    right:50%;
    top:50%;
    left: auto;
}
.slider--small-left-bottom .slick-prev:hover:before,
.slider--small-left-bottom .slick-prev:focus:before,
.slider--small-left-bottom .slick-next:hover:before,
.slider--small-left-bottom .slick-next:focus:before {
    color:#fff;
}