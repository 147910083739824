.cart-container {
    background: #fff;
    border-radius: .25rem;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
}

.cart-sidebar {
    @media (max-width: 767px) {
        display: none;
    }
}

.cart-item {
    padding:1.5rem 1.25rem;
    border-top:.125rem solid #F7F7F7;
}

.cart-item + .cart-item  {
    border-top:.125rem solid #F7F7F7;
}

.cart-line {
    margin:.875rem 0;
}

.cart-item__edit.cart-item__edit {
    font-family: var(--font-bold);
    font-size:.875rem;
}
.cart-item__prices {
    padding:.5rem 0;
    line-height: 1;
}

@media (max-width: 767px) {
    .cart-item__prices-col {
        margin:.5rem 0;
    }
}
@media (min-width: 768px) {
    .cart-item__prices-col + .cart-item__prices-col {
        border-left:.125rem solid #E3E3E3;
    }
}
.cart-item__prices-price {
    font-family: var(--font-bold);
    font-size:1.5rem;
}


/* mobile sticky */
.sticky-cart {
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    background: #fff;
    z-index:50;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.125);
    transition: 250ms ease;
    transform: translateY(0);

    @media (min-width: 768px) {
        display: none;
    }
}
.sticky-cart--hidden {
    transform: translateY(150%);
}
.sticky-cart__inner {
    font-size:calc(14rem / 16);
    padding:1rem 1rem .75rem 1rem;
}