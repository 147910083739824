.form-select,
.form-control {
    border-radius: .25rem;
    border:1px solid var(--color-darkgrey);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
}
.form-control--auto {
    width: auto;
}
.hide-input-styling>input{
    border: none;
    box-shadow: none;
}
.form-control--blank {
    padding-top:0;
    padding-left:0;
    padding-bottom:0;
    border:0;
    height: auto;
    font-size:inherit;
    color:inherit;
    font-family: inherit;
    box-shadow: none;
}
.form-control--date {
    background-repeat: no-repeat;
    background-position: 95% 50%;
    background-image:url(../icons/svg/calendar.svg);
}
.form-control--search {
    padding-right:2.5rem;
    background-repeat: no-repeat;
    background-position: 95% 50%;
    background-image:url(../icons/svg/search.svg);
    background-size:1.125rem 1.125rem;
}
.form-control--poi {
    padding-left:3.5rem;
    background-repeat: no-repeat;
    background-position: 1.125rem 50%;
    background-image:url(../icons/svg/poi.svg);
    background-size:1.25rem 1.25rem;
}
.form-label {
    font-size:.875rem;
    font-family: var(--font-medium);
    margin: 0;
}
.form-check--large .form-label,
.form-check--large .form-check-label {
    font-size: 1.125rem;
    font-family: var(--font-bold);
}
.form-group {
    margin-bottom: 1rem;
}
.form-control--shadow {
    border:0;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
}


.form-check--large {
    padding-left:2.5rem;
}
.form-check--large .form-check-input,
.form-check-input--large {
    width:1.5rem;
    height:1.5rem;
}
.form-check--large .form-check-input {
    margin-left:-2.5rem;
}

.input-group-prepend .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group-append .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}



/* required indicator */
.form-requirement__visible .form-label.required:after {
    content: "*";
    /*color: var(--color-danger);*/
}
/* mandatory indicator */
.form-requirement__visible .form-label.mandatory:after {
    content: "*";
    /*color: var(--color-warning);*/
}
