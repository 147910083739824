.date-input {
    position: relative;
}
.date-input__btn {
    margin: 0;
    padding: .5rem 1rem;
    border: 0;
    background:transparent;
    position: absolute;
    right:0;
    top:.125rem;
    font-size:1.5rem;
    color:var(--color-primary);
    z-index: 10;
}