.product-grid {
    margin-bottom: 4rem;
}

.product-item {
    font-size:.875rem;
    position: relative;
    border-radius:.25rem;
    padding:1rem;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);

    @media screen and (max-width: 575px) {
        padding-bottom:0;
    }

    @media screen and (min-width: 992px) {
        padding:1.25rem;
    }
}
.product-item__title > a {
    text-decoration: none;
    color:inherit;
}
.product-item__title > a:hover,
.product-item__title > a:focus {
    color:var(--color-primary);
}
.product-item__images {
    position: relative;

    @media screen and (max-width: 767px) {
        margin-bottom: 1rem;
    }
}
.product-item__body {
    @media screen and (max-width: 767px) {
        margin-bottom: 1rem;
    }
}
.product-item__badge-holder {
    position: absolute;
    right:-.75rem;
    top:-.75rem;
    z-index: 10;
}
.product-item__badge {
    margin-bottom:.25rem;
}
.product-item__top-seller-badge{
    background-color: var(--color-secondary);
    color: #fff;
    font-family: var(--font-bold);
    font-size: .75rem;
    line-height: 1.2;

    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    text-align: center;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    transform: rotate(-15deg);
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
        font-size: .9375rem;
        width: 4rem;
        height: 4rem;
    }
}

.product-item__top-seller-badge__inner{
    text-align: center;
    position: absolute;
    left:0;
    right:0;
    top: 50%;
    transform: translateY(-50%);
}

.product-item__desc {
    margin:.5rem 0 .75rem 0;
}
.product-item__list {
    padding-left:1rem;
}
.product-item__link {
    color:var(--color-primary);
    &:hover {
        text-decoration: underline;
    }
}
.product-item__price-line {
    margin:.125rem 0;
}
.product-item__price-item {
    margin-bottom:.5rem;
}
.product-item__price-item + .product-item__price-item {
    padding-top:.625rem;
    border-top:.125rem solid #F7F7F7;
}
.product-item__prices {
    font-size:1rem;
    line-height: 1.2;
}
.product-item__warning {
    font-size:.875rem;
    text-align:center;
    background:#fce6e7;
    padding:.875rem;
    margin:-1.25rem -1.325rem .75rem 0;

    @media screen and (max-width: 767px) {
        margin:0 -1.325rem .75rem  -1.325rem;
    }
}
.product-item__not-available {
    font-size:.875rem;
    color:var(--color-muted);
    font-family: var(--font-medium);
}
.product-item__properties {
    margin-top:.375rem;
    line-height: 1;
}
.product-item__properties .icon {
    font-size:1.75rem;
}

.product-item__detail-btn {
    width: 100%;
    @media screen and (max-width: 767px) {
        position: relative;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    @media screen and (max-width: 575px) {
        margin:0 -1.25rem;
        width:calc(100% + 2.5rem);
    }
    @media screen and (min-width: 576px) and (max-width: 767px) {
        margin:0 -1.25rem -1rem 0;
        width:calc(100% + 1.25rem);
    }
}


/* persons */
.person-selection {

}
.person-selection__person {
    font-size: 1.125rem;
}
.person-selection__person > .icon {
    margin-right:.5rem;
    vertical-align: -.2em;
    font-size: 1.5rem;
}