.booking-panel {
    border-radius: calc(4rem/16);
    background-color: #FFFFFF;
    box-shadow: 0 5px 10px 0 rgba(51,51,51,0.1);
}

.booking-panel--previous-step {
    display: flex;
    align-items: center;
    margin-left: calc(16rem/16);
    margin-right: calc(16rem/16);
    padding: calc(16rem/16) calc(20rem/16);
    color: var(--color-muted);
    font-family: var(--font-medium);
}

.booking-panel__title {
    display: flex;
    align-items: center;

    padding: calc(20rem/16) calc(36rem/16);
    font-family: var(--font-medium);
    font-size: calc(14rem/16);
    border-bottom: calc(2rem/16) solid var(--color-lightgrey);
}

.booking-panel__title--calendar {
    @media (max-width: 767px) {
        min-height: calc(84rem/16);
    }
}

.booking-panel__title-icon {
    font-size: calc(22rem/16);
}

.booking-panel__body {
    padding: calc(30rem/16);
}

.booking-panel__hr {
    margin: calc(30rem/16) 0;
    border: none;
    border-top: calc(2rem/16) solid var(--color-lightgrey);
    opacity: 1;
}