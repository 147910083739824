.checkout-box {
    position: relative;
    border-radius:.25rem;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
}
.checkout-box__inner {
    padding:1.25rem 1.75rem 1.75rem 1.75rem;
    @media screen and (max-width: 767px) {
        padding:1rem 1.25rem 1.25rem 1.25rem;
    }
}
.checkout-box__inner + .checkout-box__inner {
    border-top:.125rem solid #F7F7F7;
}
.checkout-payment-info {
    margin-top:.5rem;
    padding-left:2.5rem;
}
.payment-icon {
    display: inline-block;
    height:1.5rem;
    width:auto;
    margin:.5rem;
}



/* checkout prices */
.checkout-pricedivider {
    margin:1rem 0;
    height:.125rem;
    background: #F7F7F7;
}
.checkout-priceitem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:.625rem 0;
}
.checkout-priceitem__mainprice {
    font-size: 1.375rem;
    white-space: nowrap;
}
.checkout-priceinfo {
    margin-top:2rem;
    font-size: .75rem;
}