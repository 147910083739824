@font-face {
    font-family: "iconfont";
    src: url('static/icons/font/iconfont.woff2') format('woff2'),
    url('static/icons/font/iconfont.woff') format('woff'),
    url('static/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-addthis:before { content: "\EA01" }
.icon-adult:before { content: "\EA02" }
.icon-arrow-back:before { content: "\EA03" }
.icon-arrow-down:before { content: "\EA04" }
.icon-arrow-left:before { content: "\EA05" }
.icon-arrow-right:before { content: "\EA06" }
.icon-bike-battery-exchange:before { content: "\EA07" }
.icon-bike-battery:before { content: "\EA08" }
.icon-bike-direction-downhill:before { content: "\EA09" }
.icon-bike-direction-straight:before { content: "\EA0A" }
.icon-bike-direction-uphill:before { content: "\EA0B" }
.icon-bike-type-city:before { content: "\EA0C" }
.icon-bike-type-downhill:before { content: "\EA0D" }
.icon-bike-type-fully:before { content: "\EA0E" }
.icon-bike-type-hardtail:before { content: "\EA0F" }
.icon-bike-type-racing:before { content: "\EA10" }
.icon-bike:before { content: "\EA11" }
.icon-calendar:before { content: "\EA12" }
.icon-check-circle:before { content: "\EA13" }
.icon-check:before { content: "\EA14" }
.icon-child:before { content: "\EA15" }
.icon-city:before { content: "\EA16" }
.icon-clock:before { content: "\EA17" }
.icon-close:before { content: "\EA18" }
.icon-conflict-info:before { content: "\EA19" }
.icon-dealer-big:before { content: "\EA1A" }
.icon-dealer:before { content: "\EA1B" }
.icon-delete:before { content: "\EA1C" }
.icon-depot:before { content: "\EA1D" }
.icon-download:before { content: "\EA1E" }
.icon-edit-more:before { content: "\EA1F" }
.icon-edit:before { content: "\EA20" }
.icon-elements:before { content: "\EA21" }
.icon-exclamation-circle:before { content: "\EA22" }
.icon-exclamation-triangle:before { content: "\EA23" }
.icon-external-link:before { content: "\EA24" }
.icon-facebook:before { content: "\EA25" }
.icon-faq:before { content: "\EA26" }
.icon-filter:before { content: "\EA27" }
.icon-fullscreen:before { content: "\EA28" }
.icon-gondel:before { content: "\EA29" }
.icon-google:before { content: "\EA2A" }
.icon-haircross:before { content: "\EA2B" }
.icon-heart:before { content: "\EA2C" }
.icon-hotel:before { content: "\EA2D" }
.icon-hotline:before { content: "\EA2E" }
.icon-info-konto:before { content: "\EA2F" }
.icon-info-simple:before { content: "\EA30" }
.icon-info:before { content: "\EA31" }
.icon-ir-2021-arrow-right:before { content: "\EA32" }
.icon-ir-2021-bag:before { content: "\EA33" }
.icon-ir-2021-bike:before { content: "\EA34" }
.icon-ir-2021-chevron:before { content: "\EA35" }
.icon-ir-2021-house:before { content: "\EA36" }
.icon-ir-2021-lift:before { content: "\EA37" }
.icon-ir-2021-lightbulb:before { content: "\EA38" }
.icon-ir-2021-map-with-magnifier:before { content: "\EA39" }
.icon-ir-2021-menu:before { content: "\EA3A" }
.icon-ir-2021-play:before { content: "\EA3B" }
.icon-ir-2021-rating:before { content: "\EA3C" }
.icon-ir-2021-resorts:before { content: "\EA3D" }
.icon-ir-2021-trail:before { content: "\EA3E" }
.icon-ir-2021-umbrella:before { content: "\EA3F" }
.icon-ir-2021-webcam:before { content: "\EA40" }
.icon-ir-2021-youtube:before { content: "\EA41" }
.icon-language:before { content: "\EA42" }
.icon-linkedin:before { content: "\EA43" }
.icon-liste:before { content: "\EA44" }
.icon-lkw:before { content: "\EA45" }
.icon-local-shop:before { content: "\EA46" }
.icon-location:before { content: "\EA47" }
.icon-lock:before { content: "\EA48" }
.icon-mail:before { content: "\EA49" }
.icon-map:before { content: "\EA4A" }
.icon-master-poi:before { content: "\EA4B" }
.icon-minus:before { content: "\EA4C" }
.icon-passbook:before { content: "\EA4D" }
.icon-pinterest:before { content: "\EA4E" }
.icon-plus:before { content: "\EA4F" }
.icon-poi:before { content: "\EA50" }
.icon-produkte:before { content: "\EA51" }
.icon-rating:before { content: "\EA52" }
.icon-refresh:before { content: "\EA53" }
.icon-resize:before { content: "\EA54" }
.icon-rounded-checkmark:before { content: "\EA55" }
.icon-search:before { content: "\EA56" }
.icon-settings:before { content: "\EA57" }
.icon-shop:before { content: "\EA58" }
.icon-ski:before { content: "\EA59" }
.icon-smiley-filled:before { content: "\EA5A" }
.icon-smiley:before { content: "\EA5B" }
.icon-star-empty:before { content: "\EA5C" }
.icon-star-full:before { content: "\EA5D" }
.icon-support:before { content: "\EA5E" }
.icon-twitter:before { content: "\EA5F" }
.icon-user:before { content: "\EA60" }
.icon-walking-distance:before { content: "\EA61" }
.icon-warenkorb:before { content: "\EA62" }
.icon-whatsapp:before { content: "\EA63" }


:root {
--icon-addthis: "\EA01";
    --icon-adult: "\EA02";
    --icon-arrow-back: "\EA03";
    --icon-arrow-down: "\EA04";
    --icon-arrow-left: "\EA05";
    --icon-arrow-right: "\EA06";
    --icon-bike-battery-exchange: "\EA07";
    --icon-bike-battery: "\EA08";
    --icon-bike-direction-downhill: "\EA09";
    --icon-bike-direction-straight: "\EA0A";
    --icon-bike-direction-uphill: "\EA0B";
    --icon-bike-type-city: "\EA0C";
    --icon-bike-type-downhill: "\EA0D";
    --icon-bike-type-fully: "\EA0E";
    --icon-bike-type-hardtail: "\EA0F";
    --icon-bike-type-racing: "\EA10";
    --icon-bike: "\EA11";
    --icon-calendar: "\EA12";
    --icon-check-circle: "\EA13";
    --icon-check: "\EA14";
    --icon-child: "\EA15";
    --icon-city: "\EA16";
    --icon-clock: "\EA17";
    --icon-close: "\EA18";
    --icon-conflict-info: "\EA19";
    --icon-dealer-big: "\EA1A";
    --icon-dealer: "\EA1B";
    --icon-delete: "\EA1C";
    --icon-depot: "\EA1D";
    --icon-download: "\EA1E";
    --icon-edit-more: "\EA1F";
    --icon-edit: "\EA20";
    --icon-elements: "\EA21";
    --icon-exclamation-circle: "\EA22";
    --icon-exclamation-triangle: "\EA23";
    --icon-external-link: "\EA24";
    --icon-facebook: "\EA25";
    --icon-faq: "\EA26";
    --icon-filter: "\EA27";
    --icon-fullscreen: "\EA28";
    --icon-gondel: "\EA29";
    --icon-google: "\EA2A";
    --icon-haircross: "\EA2B";
    --icon-heart: "\EA2C";
    --icon-hotel: "\EA2D";
    --icon-hotline: "\EA2E";
    --icon-info-konto: "\EA2F";
    --icon-info-simple: "\EA30";
    --icon-info: "\EA31";
    --icon-ir-2021-arrow-right: "\EA32";
    --icon-ir-2021-bag: "\EA33";
    --icon-ir-2021-bike: "\EA34";
    --icon-ir-2021-chevron: "\EA35";
    --icon-ir-2021-house: "\EA36";
    --icon-ir-2021-lift: "\EA37";
    --icon-ir-2021-lightbulb: "\EA38";
    --icon-ir-2021-map-with-magnifier: "\EA39";
    --icon-ir-2021-menu: "\EA3A";
    --icon-ir-2021-play: "\EA3B";
    --icon-ir-2021-rating: "\EA3C";
    --icon-ir-2021-resorts: "\EA3D";
    --icon-ir-2021-trail: "\EA3E";
    --icon-ir-2021-umbrella: "\EA3F";
    --icon-ir-2021-webcam: "\EA40";
    --icon-ir-2021-youtube: "\EA41";
    --icon-language: "\EA42";
    --icon-linkedin: "\EA43";
    --icon-liste: "\EA44";
    --icon-lkw: "\EA45";
    --icon-local-shop: "\EA46";
    --icon-location: "\EA47";
    --icon-lock: "\EA48";
    --icon-mail: "\EA49";
    --icon-map: "\EA4A";
    --icon-master-poi: "\EA4B";
    --icon-minus: "\EA4C";
    --icon-passbook: "\EA4D";
    --icon-pinterest: "\EA4E";
    --icon-plus: "\EA4F";
    --icon-poi: "\EA50";
    --icon-produkte: "\EA51";
    --icon-rating: "\EA52";
    --icon-refresh: "\EA53";
    --icon-resize: "\EA54";
    --icon-rounded-checkmark: "\EA55";
    --icon-search: "\EA56";
    --icon-settings: "\EA57";
    --icon-shop: "\EA58";
    --icon-ski: "\EA59";
    --icon-smiley-filled: "\EA5A";
    --icon-smiley: "\EA5B";
    --icon-star-empty: "\EA5C";
    --icon-star-full: "\EA5D";
    --icon-support: "\EA5E";
    --icon-twitter: "\EA5F";
    --icon-user: "\EA60";
    --icon-walking-distance: "\EA61";
    --icon-warenkorb: "\EA62";
    --icon-whatsapp: "\EA63";
    
}