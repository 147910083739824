.number-spinner .form-control {
    padding-left:0;
    padding-right:0;
    text-align: center;
    border-color:var(--color-primary);
}
.btn.btn-number-spinner {
    padding:.875rem 1.125rem;
}
.btn-number-spinner > .icon {
    vertical-align: -.15em;
}


/* persons */
.persons-spinner {
    padding-left:.625rem;
    color:var(--color-primary);
    border-radius:.25rem;
    background: #fff;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
    width:100%;
    max-width:calc(350rem / 16);
    margin:0 auto;
}
.persons-spinner--blank {
    background: transparent;
    box-shadow: none;
}
.persons-spinner + .persons-spinner,
.persons-spinner + .child-count {
    margin-top:1.5rem;
}
.persons-spinner__icon {
    font-size:1.25rem;
    vertical-align: -0.25em;
}
.persons-spinner__ageinfo {
    font-size:.875rem;
    line-height: 1;
    padding-left:.25rem;
}
.persons-spinner__main {
    font-family: var(--font-medium);
    font-size:.9375rem;
    line-height: 1;
    padding-left:.25rem;
}