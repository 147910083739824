.social-login-buttons button{
    color:#fff;
}
.social-login-buttons--dropdown {
    margin-top: 1.25rem;
}
.social-login-buttons--dropdown button {
    margin-bottom: .375rem;
    width: 100%;
}
.social-login-buttons--modal button {
    margin:0 .125rem .375rem .125rem;
}
.social-login-buttons .social-login--google{
    background: #4285F4;
}
.social-login-buttons .social-login--facebook{
    background: #3b5998;
}
.social-login-buttons .social-login--swisspass{
    background: #c51416;
}
.social-login-buttons .social-login__icon{
    font-size: calc(20rem/16);
    vertical-align: -.25em;
    position: relative;
    margin-right:.375rem;
}


