.slide-heading,
.section-headlines {
    margin-bottom: calc(20rem/16);
    color:var(--color-primary);
}
.slide-heading__icon {
    display:none; /* IR 2021 layout anpassung */
}
.slide-heading__title.slide-heading__title,
.section-headlines__main.section-headlines__main {
    font-family: var(--font-bold);
    font-size:calc(25rem/16);
    margin:0 0 calc(5rem/16) 0;
    color:inherit;
}
.slide-heading__subtitle.slide-heading__subtitle,
.section-headlines__sub.section-headlines__sub {
    font-family: var(--font-default);
    font-size:1.125rem;
    margin:0 0 calc(5rem/16) 0;
    color:inherit;
}


@media screen and (min-width: 768px){
    .slide-heading,
    .section-headlines {
        margin-bottom: calc(40rem/16);
    }
    .slide-heading__title.slide-heading__title,
    .slide-heading__subtitle--big,
    .section-headlines__main.section-headlines__main {
        font-size:calc(35rem/16);
    }
    .slide-heading__subtitle.slide-heading__subtitle,
    .section-headlines__sub.section-headlines__sub {
        font-size:calc(20rem/16);
    }
}