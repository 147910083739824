.breadcrumb {
    color:var(--color-primary);
    margin-bottom: 0;
    background: transparent;
    padding:0;
    align-items: center;
}
.breadcrumb>li+li:before {
    position: relative;
    padding: 0 calc(8rem/16) 0 calc(2rem/16);
    color:var(--color-primary);
}
.breadcrumb>li:first-child>a {
    font-size:0;
    width: calc(20rem/16);
    height:calc(20rem/16);
    display: inline-block;
}
.breadcrumb>li:first-child>a:before {
    display: inline-block;
    font-size:calc(18rem/16);
    font-family: iconfont;
    content: var(--icon-ir-2021-house);
    pointer-events: none;
}
.breadcrumb-wrapper {
    padding:calc(12rem/16) 0 calc(10rem/16) 0;
    background: rgba(234,233,233,0.5);
    @media screen and (min-width: 768px) {
        font-size:calc(16rem/16);
    }
}

@media screen and (min-width: 768px) {
    .magazin-slider + .breadcrumb-wrapper {
        margin-top:calc(-70rem/16);
    }
}
@media screen and (min-width: 768px) and (max-width: 1199px){
    .breadcrumb-wrapper {
        margin-bottom:calc(40rem/16);
    }
    .magazin-slider + .breadcrumb-wrapper {
        margin-top:calc(-40rem/16);
    }
}

@media screen and (min-width: 1200px){
    .breadcrumb-wrapper {
        margin-bottom: calc(55rem/16);
    }
}