.flag {
    display: inline-block;
    width: calc(21rem/16);
    height: calc(15rem/16);
    background-image: url(img/flags/sprite-flags.png);
    background-repeat: no-repeat;
    background-size: calc(21rem/16) auto;
    vertical-align: calc(-2rem/16);
    margin-right: calc(5rem/16);
}
.flag--at {
    background-position: 0 0;
}
.flag--ba {
    background-position: 0 calc(-22rem/16);
}
.flag--bg {
    background-position: 0 calc(-45rem/16);
}
.flag--cz {
    background-position: 0 calc(-67rem/16);
}
.flag--de {
    background-position: 0 calc(-91rem/16);
}
.flag--hu {
    background-position: 0 calc(-113rem/16);
}
.flag--hr {
    background-position: 0 calc(-136rem/16);
}
.flag--pl {
    background-position: 0 calc(-159rem/16);
}
.flag--ro {
    background-position: 0 calc(182rem/16);
}
.flag--rs {
    background-position: 0 calc(-205rem/16);
}
.flag--ru {
    background-position: 0 calc(-227rem/16);
}
.flag--sk {
    background-position: 0 calc(-250rem/16);
}
.flag--si {
    background-position: 0 calc(-273rem/16);
}
.flag--int {
    background-position: 0 calc(-296rem/16);
}
.flag--en {
    background-position: 0 calc(-319rem/16);
}
.flag--fr {
    background-position: 0 calc(-341rem/16);
}


/* for header - language is de - flag should be at (maybe ch/de for other instances...)*/
.language-flag-de-at {
    background-position: 0 0;
}
.language-flag-de {
    background-position: 0 calc(-91rem/16);
}
/* for header - language is en - flag should be int */
.language-flag-en {
    background-position: 0 calc(-319rem/16);
}
.language-flag-fr {
    background-position: 0 calc(-341rem/16);
}

/** country is now defined in parent nav-container **/

/** defaults - aliases for .flag-- **/
.flag.language-flag-de {
    background-position: 0 calc(-91rem/16);
}
.flag.language-flag-en {
    background-position: 0 calc(-319rem/16);
}
.flag.language-flag-fr {
    background-position: 0 calc(-341rem/16);
}
.flag.language-flag-cz {
    background-position: 0 calc(-67rem/16);
}
.flag.language-flag-sk {
    background-position: 0 calc(-250rem/16);
}

.hit-flag {
    width: calc(21rem/16);
    height: calc(15rem/16);
    background-size: calc(21rem/16) calc(15rem/16);
    display: inline-block;
    background-repeat: no-repeat;
    position: relative;
    top: calc(2rem/16);
    margin-right: calc(5rem/16);
    margin-top: calc(5rem/16);
}
.hit-flag--lang {
    margin-top: 0;
}

.hit-flag-int {
    background-image: url(img/flags/int.png);
}
.hit-flag-EN,
.hit-flag-GB {
    background-image: url(/pimcore/static6/img/flags/countries/gb.svg);
}

.hit-flag-AT {
    background-image: url(/pimcore/static6/img/flags/countries/at.svg);
}
.hit-flag-DE {
    background-image: url(/pimcore/static6/img/flags/countries/de.svg);
}
.hit-flag-CH {
    background-image: url(/pimcore/static6/img/flags/countries/ch.svg);
}
.hit-flag-FR {
    background-image: url(/pimcore/static6/img/flags/countries/fr.svg);
}
.hit-flag-IT {
    background-image: url(/pimcore/static6/img/flags/countries/it.svg);
}
.hit-flag-CZ {
    background-image: url(/pimcore/static6/img/flags/countries/cz.svg);
}
.hit-flag-SK {
    background-image: url(/pimcore/static6/img/flags/countries/sk.svg);
}
.hit-flag-SI {
    background-image: url(/pimcore/static6/img/flags/countries/si.svg);
}
.hit-flag-ES {
    background-image: url(/pimcore/static6/img/flags/countries/es.svg);
}
.hit-flag-PL {
    background-image: url(/pimcore/static6/img/flags/countries/pl.svg);
}
.hit-flag-AD {
    background-image: url(/pimcore/static6/img/flags/countries/ad.svg);
}
.hit-flag-FI {
    background-image: url(/pimcore/static6/img/flags/countries/fi.svg);
}
.hit-flag-SE {
    background-image: url(/pimcore/static6/img/flags/countries/se.svg);
}
.hit-flag-CA {
    background-image: url(/pimcore/static6/img/flags/countries/ca.svg);
}
.hit-flag-NL {
    background-image: url(/pimcore/static6/img/flags/countries/nl.svg);
}

/** overrides per country **/

/** custom flag for AT-DE **/
.navbar.country-at .hit-flag-DE,
.footer.tenant-iat .hit-flag-DE {
    background-image: url(/pimcore/static6/img/flags/countries/at.svg);
}

/** custom flag for CH-DE **/
.navbar.country-ch .hit-flag-DE,
.footer.tenant-ich .hit-flag-DE {
    background-image: url(/pimcore/static6/img/flags/countries/ch.svg);
}

/*
.navbar.country-ch .hit-flag-DE,
.footer.tenant-ich .hit-flag-DE {
    background-image: url(/pimcore/static6/img/flags/countries/ch.svg);
}
 */


/** override for CH-DE - hide dropdown icons - show int for active **/
/*
.navbar.country-ch .dropdown-menu .hit-flag--lang,
.footer.tenant-ich .dropdown-menu .hit-flag--lang {
    display: none;
}
.navbar.country-ch button .hit-flag.hit-flag--lang,
.footer.tenant-ich a .hit-flag.hit-flag--lang {
    background-image: url(img/flags/int.png);
}
*/