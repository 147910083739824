body {
    font-size: calc(15rem/16);
    line-height:1.5;
    font-family: var(--font-default);
}

@media screen and (min-width: 768px) {
    body {
        font-size:calc(16rem/16);
    }
}

strong, .strong, .font-bold, .font-weight-bold{
    font-family: var(--font-bold) !important;
    font-weight: 400;
}

.font-default, .font-weight-default{
    font-family: var(--font-default) !important;
    font-weight: 400;
}

.font-medium, .font-weight-medium{
    font-family: var(--font-medium) !important;
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
    font-family: var(--font-bold);
    /*font-family: var(--font-condensed);*/
}

h1, .h1 {
    font-size: calc(20rem/16);
}
h2, .h2 {
    font-size: calc(18rem/16);
}
h3, .h3 {
    font-size: calc(16rem/16);
}
h4, .h4 {
    font-size: calc(15rem/16);
}
h5, .h5 {
    font-size: calc(14rem/16);
}
h6, .h6 {
    font-size: calc(12rem/16);
}

@media screen and (min-width: 768px) {
    h1, .h1 {
        font-size: calc(22rem/16);
    }
    h2, .h2 {
        font-size: calc(20rem/16);
    }
    h3, .h3 {
        font-size: calc(18rem/16);
    }
    h4, .h4 {
        font-size: calc(17rem/16);
    }
    h5, .h5 {
        font-size: calc(16rem/16);
        text-transform: uppercase;
    }
    h6, .h6 {
        font-size:calc(12rem/16);
    }
}

.portal-headline {
    text-align: center;
    margin-bottom: 2rem;
    font-size:calc(25rem/16);
    @media screen and (min-width:1200px) {
        font-size:calc(42rem/16);
        margin-bottom: 4rem;
    }
}

.fz12{ font-size: calc(12rem/16) !important; }
.fz13{ font-size: calc(13rem/16) !important; }
.fz14{ font-size: calc(14rem/16) !important; }
.fz15{ font-size: calc(15rem/16) !important; }
.fz16{ font-size: calc(16rem/16) !important; }
.fz18{ font-size: calc(18rem/16) !important; }
.fz20{ font-size: calc(20rem/16) !important; }
.fz22{ font-size: calc(22rem/16) !important; }


.text-2-col {
    column-count: 2;
    column-gap: calc(25rem/16);
}

.further-links-block {
    margin-bottom:2.5rem;
}

.text-link{
    text-decoration: underline;
    color: var(--color-info);
}
