.location-item {
    background: #fff;
    position: relative;
    padding:1.5rem 1.125rem 1.125rem 1.125rem;
    box-shadow: 0 5px 30px 0 rgba(51,51,51,0.1);
    height:100%;
    font-family: var(--font-default);
    &:hover {
       box-shadow: 0 5px 30px 0 rgba(51,51,51,0.25);
    }
    font-size: calc(15rem/16);
    line-height:1.5;
    @media screen and (min-width: 768px) {
        font-size:calc(16rem/16);
    }
}

.location-item--city {
    background: transparent;
    padding: 0;
    box-shadow: none;
    margin: 1.75rem 0 0.25rem 0.25rem;
}

.location-item__body {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    height:100%;
}
.location-item__content {
    margin-bottom: auto;
    flex-grow: 1;
}
.location-item__bottom .btn,
.location-item__content .btn {
    margin:0 .5rem .5rem 0;
}
.location-item__bottom {
    /*margin-top:1.5rem;*/
}
.location-item__img {
    margin-top:1.5rem;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.location-item__details {
    position: relative;
    padding-left:2.125rem;
    margin:.25rem 0;
}
.location-item__details--no-icon {
    padding-left:0;
}
.location-item__details .icon{
    position: absolute;
    left:0;
    top:0;
    font-size: 1.25rem;
}
.location-item__details .icon-poi{
    left:.125rem;
}
.location-item__details .icon-walking-distance{
    left:.5rem;
}
.location-item__details-routing.routing_distance__nearby{
    color: var(--color-text-success);
}
.location-item__details-routing.routing_distance__midway{
    color: var(--color-warning);
}
.location-item__details-routing.routing_distance__default{
    color: var(--color-default);
}


/* widget */
.location-item .booking-widget-wrapper__widget {
    display: inline;
}

/* map */
.infoBox {
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.25);
}
.infoBox>img {
    z-index: 1;
    padding:1rem;

}