/* rem gutters */
.row-gutter--0 {
    margin-left: 0;
    margin-right: 0;
}
.row-gutter--0 > [class^="col"],
.row-gutter--0 > [class*=" col"],
.row-gutter--0.slick-slider [class^="col"],
.row-gutter--0.slick-slider [class*=" col"] {
    padding-left: 0;
    padding-right: 0;
}
.row-gutter--1 {
    margin-left: -.125rem;
    margin-right: -.125rem;
}
.row-gutter--1 > [class^="col"],
.row-gutter--1 > [class*=" col"],
.row-gutter--1.slick-slider [class^="col"],
.row-gutter--1.slick-slider [class*=" col"] {
    padding-left: .125rem;
    padding-right: .125rem;
}
.row-gutter--2 {
    margin-left: -.25rem;
    margin-right: -.25rem;
}
.row-gutter--2 > [class^="col"],
.row-gutter--2 > [class*=" col"],
.row-gutter--2.slick-slider [class^="col"],
.row-gutter--2.slick-slider [class*=" col"] {
    padding-left: .25rem;
    padding-right: .25rem;
}
.row-gutter--3 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}
.row-gutter--3 > [class^="col"],
.row-gutter--3 > [class*=" col"],
.row-gutter--3.slick-slider [class^="col"],
.row-gutter--3.slick-slider [class*=" col"] {
    padding-left: .5rem;
    padding-right: .5rem;
}
.row-gutter--4 {
    margin-left: -.75rem;
    margin-right: -.75rem;
}
.row-gutter--4 > [class^="col"],
.row-gutter--4 > [class*=" col"],
.row-gutter--4.slick-slider [class^="col"],
.row-gutter--4.slick-slider [class*=" col"] {
    padding-left: .75rem;
    padding-right: .75rem;
}



@media screen and (max-width: 767px){
    .row.row--gutter-width-0-xs {
        margin-left: 0;
        margin-right: 0;
    }
    .row.row--gutter-width-0-xs > [class^="col"],
    .row.row--gutter-width-0-xs > [class*=" col"]{
        padding-left: 0;
        padding-right: 0;
    }
}

/*use a media query for bigger row gutters; otherwise the page is scrollable on mobile devices */
@media screen and (min-width: 768px) {
    .row-gutter--5 {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
    .row-gutter--5 > [class^="col"],
    .row-gutter--5 > [class*=" col"],
    .row-gutter--5.slick-slider [class^="col"],
    .row-gutter--5.slick-slider [class*=" col"] {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}


/***********************************************************
               ATTENTION: Deprecated
    Pixel Gutters should not be used any more.
    They are included for backward compatibility only.
    Please use rem gutters instead

 ***********************************************************/


/* pixel gutters */
.row.row--gutter-width-0 {
    margin-left: 0;
    margin-right: 0;
}
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"],
.row--gutter-width-0.slick-slider [class^="col"],
.row--gutter-width-0.slick-slider [class*=" col"] {
    padding-left: 0;
    padding-right: 0;
}
.row.row--gutter-width-10 {
    margin-left: -5px;
    margin-right: -5px;
}
.row--gutter-width-10 > [class^="col"],
.row--gutter-width-10 > [class*=" col"],
.row--gutter-width-10.slick-slider [class^="col"],
.row--gutter-width-10.slick-slider [class*=" col"] {
    padding-left: 5px;
    padding-right: 5px;
}
.row.row--gutter-width-20 {
    margin-left: -10px;
    margin-right: -10px;
}
.row--gutter-width-20 > [class^="col"],
.row--gutter-width-20 > [class*=" col"],
.row--gutter-width-20.slick-slider [class^="col"],
.row--gutter-width-20.slick-slider [class*=" col"] {
    padding-left: 10px;
    padding-right: 10px;
}



/* old row gutters */
/** Custom row gutters **/

.row.row--gutter-width-0 {
    margin-left: 0;
    margin-right: 0;
}
.row.row--gutter-width-0 > [class^="col"]{
    padding-left: 0;
    padding-right: 0;
}

.row.row--gutter-width-10 {
    margin-left: -5px;
    margin-right: -5px;
}
.row.row--gutter-width-10>[class^="col"] {
    padding-left: 5px;
    padding-right: 5px;
}

.row.row--gutter-width-8 {
    margin-left: -4px;
    margin-right: -4px;
}
.row.row--gutter-width-8>[class^="col"] {
    padding-left: 4px;
    padding-right: 4px;
}

.row.row--gutter-width-20 {
    margin-left: -10px;
    margin-right: -10px;
}
.row.row--gutter-width-20>[class^="col"] {
    padding-left: 10px;
    padding-right: 10px;
}
.row.row--gutter-width-24 {
    margin-left: -12px;
    margin-right: -12px;
}
.row.row--gutter-width-24>[class^="col"] {
    padding-left: 12px;
    padding-right: 12px;
}
.row--gutter-width-variable {
    margin-left: -5px;
    margin-right: -5px;
}
.row--gutter-width-variable>[class^="col"], .row--gutter-width-variable>form>[class^="col"] {
    padding-left: 5px;
    padding-right: 5px;
}
@media screen and (min-width: 1200px){
    .row--gutter-width-variable {
        margin-left: -10px;
        margin-right: -10px;
    }
    .row--gutter-width-variable>[class^="col"], .row--gutter-width-variable>form>[class^="col"] {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.row--vertical-gutter-8 {
    margin-top: -8px;
    clear: both;
}
.row--vertical-gutter-8 > [class^="col"] {
    margin-top: 8px;
}
.row--vertical-gutter-10 {
    margin-top: -10px;
    clear: both;
}
.row--vertical-gutter-10 > [class^="col"] {
    margin-top: 10px;
}

.row--vertical-gutter-15 {
    margin-top: -15px;
    clear: both;
}
.row--vertical-gutter-15 > [class^="col"] {
    margin-top: 15px;
}

.row--vertical-gutter-30 {
    margin-top: -30px;
    clear: both;
}
.row--vertical-gutter-30 > [class^="col"] {
    margin-top: 30px;
}

.row--vertical-gutter-24 {
    margin-top: -24px;
    clear: both;
}
.row--vertical-gutter-24 > [class^="col"] {
    margin-top:24px;
}

.row--vertical-gutter-40 {
    margin-top: -40px;
    clear: both;
}
.row--vertical-gutter-40 > [class^="col"] {
    margin-top: 40px;
}

@media screen and (max-width: 767px){
    .row.row--gutter-width-0-xs {
        margin-left: 0;
        margin-right: 0;
    }
    .row.row--gutter-width-0-xs > [class^="col"]{
        padding-left: 0;
        padding-right: 0;
    }
}