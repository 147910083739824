.modal-dialog {
    @media (min-width: 576px) {
        max-width: 90vw;
    }
    @media (min-width: 1100px) {
        max-width: calc(1040rem / 16);
    }
}

@media (max-width: 767px) {
    .modal--100h {
        z-index:9999999995 !important; /* z-index must be higher than chatwidget */
    }
    /*100% height modal*/
    body:not(.iframe) .modal .modal-dialog {
        margin:0 auto;
    }
    body:not(.iframe) .modal--100h .modal-content {
        min-height:100vh;
    }
}

.modal-content {
    background:#f7f7f7;
}
.modal-header {
    display: block;
    position: relative;
    background: #fff;
    padding:.75rem 3rem;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
    text-align: center;
    font-size:.9375rem;
    font-family: var(--font-medium);
    text-transform: none;
}
.modal-close {
    border:0;
    -webkit-appearance:none;
    background: transparent;
    color:var(--color-primary);
    font-size:calc(15rem/16);
    text-align: center;
    width:calc(45rem/16);
    height:auto;
    padding-top:.5rem;
    display: block;
    position: absolute;
    right:0;
    top:0;
    bottom:0;
    border-left:.125rem solid #F7F7F7;
}
.modal-body {
    padding:1.875rem 1.25rem;
    width:100%;
    margin-left:auto;
    margin-right:auto;
    max-width:calc(930rem/16);
    min-height:5rem;

    @media screen and (min-width: 768px) {
        padding:2.5rem;
    }
}

.modal-edit-item {
    border-radius:.25rem;
    background: #fff;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
    width:100%;
    max-width:calc(350rem / 16);
    margin:0 auto;
    position: relative;
}

.modal-edit-item + .modal-edit-item {
    margin-top:1.5rem;
}
.modal-edit-item--medium {
    max-width:calc(580rem / 16);
}
.modal-edit-item--wide {
    max-width:none;
}
.modal-edit-item__inner {
    padding:1rem;

    @media screen and (min-width: 768px) {
        padding:1.25rem;
    }
}
.modal-edit-item__inner + .modal-edit-item__inner {
    border-top:.125rem solid #F7F7F7;
}
.modal-item__narrow-content {
    margin:0 auto;
    width: 100%;
    max-width:calc(250rem / 16);
}

.modal-footer:not(.modal-footer--persons) {
    padding:0;
}
.modal-footer--persons {
    display: block;
}
.modal-footer--sticky {
    position: sticky;
    bottom:0;
    z-index:50;
}
.modal-footer__progress {
    padding:.75rem 1rem .125rem 1rem;

    @media screen and (min-width: 768px) {
        padding:1rem 2rem;
    }
}



/* modal item box */
.modal-item__inner {
    font-size:.875rem;
    font-family: var(--font-medium);
    padding:1rem;
    background: #fff;
    border-radius: .25rem;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);

    @media screen and (min-width: 768px) {
        padding:1.25rem 1.25rem 0 1.25rem;
    }
}
.modal-item__inner--single {
    padding:1rem;
    margin:1rem 0;

    @media screen and (min-width: 768px) {
        padding:1.25rem;
    }
}
.modal-item__price {
    font-size:.9375rem;
    white-space: nowrap;
    font-family: var(--font-bold);

    @media screen and (min-width: 768px) {
        font-size:1.125rem;
    }
}
.modal-item__img {
    display: block;
    margin:0 auto;
    width: calc(83rem / 16);
}
.modal-item__prices {
    text-align: right;
    font-family: var(--font-medium);
    font-size:.875rem;
    padding:2rem 0;
    border-top:.125rem solid #F7F7F7;
}
.modal-item__prices-price {
    font-family: var(--font-bold);
    font-size:1.5rem;
}
.modal-item__price-item {
    line-height: 1;
    display: inline-block;
}
.modal-item__price-item + .modal-item__price-item {
    margin-left:1.25rem;
    padding-left:1.25rem;

    @media screen and (min-width: 768px) {
        border-left:2px solid;
    }
}

.modal-item__cta {
    position: relative;
    margin:0 -1.25rem;
    width:auto;
}