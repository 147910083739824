.fancy-checkbox span{
    display: inline-block;
    margin-right:.25rem;
    margin-bottom: .125rem;
}
.fancy-checkbox .fancy-checkbox__txt{
    position: relative;
    display: inline-block;
    border: none;
    font-family: var(--font-bold);
    font-size: calc(14rem/16);
    padding: calc(6rem/16) calc(35rem/16);
    border-radius: .25rem;
    transition: all 150ms ease;
    cursor: pointer;
    background:#fff;
    overflow: hidden;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
}
.fancy-checkbox input:hover + .fancy-checkbox__txt {
    color:#fff;
    background-color:var(--color-primary);
}
.fancy-checkbox input:checked + .fancy-checkbox__txt {
    color:#fff;
    background-color:var(--color-primary);
}

.fancy-checkbox .icon{
    display:none;
}
.fancy-checkbox input:checked + .fancy-checkbox__txt .icon {
    font-size:.625rem;
    position: absolute;
    left:.875rem;
    top:.625rem;
    display: inline-block;
}

.fancy-checkbox input {
    position: absolute;
    left:-1500rem;
    display: none;
}