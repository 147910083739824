.accordion-item {
    background: transparent !important;
    color:var(--color-text-default) !important;
    margin:.5rem 0;
    border:1px solid var(--color-darkgrey) !important;
}
.accordion-button {
    box-shadow: none !important;
    border:0 !important;
    background: transparent !important;
    color:var(--color-text-default) !important;
}
.accordion-button:after {
    position: relative;
    top:.125rem;
    content: var(--icon-arrow-down);
    font-family: "iconfont";
    font-size: .75rem;
    color: var(--color-text-default);
    background: none !important;
    transform: none !important;
}
.accordion-button:not(.collapsed):after {
    transform: scaleY(-1) translateY(0.375rem) !important;
}