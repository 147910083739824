.clothing-sizes-img-container {
    background-color: var(--color-grey-dark);
    padding:1rem;
}

/* table */
.table.table-clothing-sizes td {
    text-align: center;
}
.table.table-clothing-sizes td,
.table.table-clothing-sizes th {
    padding:.5rem;
    font-size:calc(12rem/16);
}
@media screen and (max-width: 991px){
    .table-clothing-sizes {
        width:45rem !important;
        max-width: none !important;
    }
    .table-clothing-sizes-responsive {
        margin-left:10rem;
    }
    .table-clothing-sizes-responsive:after {
        content:'';
        top:0;
        bottom:0;
        right:0;
        width:2rem;
        position: absolute;
        background: linear-gradient(90deg,rgba(255,255,255,0) 0,#fff 100%);
    }
    .table-clothing-sizes__headcol {
        position: absolute;
        width: 10rem;
        left: 0;
        top: auto;
        padding-right:0;
        white-space: nowrap;
    }
}