.regions-teaser {
    position: relative;
    box-shadow: 0 22px 15px -20px rgba(0,0,0,0.125);
    transition: all 0.2s linear;
}
.regions-teaser:hover,
.regions-teaser:focus {
    box-shadow: 0 22px 15px -20px rgba(0,0,0,0.5);
    transform: translateY(-10px);
}
.regions-teaser__body {
    position: absolute;
    left:0;
    right: 0;
    bottom:0;
    top: auto;
    height: auto;
}
.regions-teaser__body-inner {
    position: relative;
    z-index:10;
    padding:1.25rem;
    font-size:calc(15rem/16);
    color:#fff;
    line-height:1.25;
}
.regions-teaser__body:before {
    content:'';
    display: block;
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background-color: var(--color-primary-trans);
    mix-blend-mode: multiply;
    z-index:5;
}
.regions-teaser__headline {
    font-size:1.25rem;
    margin: 0;
    font-family: var(--font-bold);
}
.regions-teaser__label {
    font-size: calc(14rem/16);
    margin-bottom: calc(5rem/16);
}
.regions-teaser .rating__stars {
    margin-bottom:calc(12rem/16);
}
@media screen and (min-width: 1200px) {
    .regions-teaser__body-inner {
        line-height:1.65;
        padding:calc(30rem/16);
    }
    .regions-teaser__headline {
        font-size:calc(25rem/16);
    }
    .regions-teaser__label {
        font-size: 1rem;
    }
}