:root {
    --navbar-mobile-height: calc(55rem/16);
    --navbar-height: calc(80rem/16);
}
.main-navbar{
    background: #fff;
    color: var(--color-default);
    border-radius: 0;
    padding:0;
    border: none;
    height:var(--navbar-mobile-height);
    position:relative;
    border-bottom: 1px solid #dadada;
    z-index: 200;

    @media screen and (min-width: 768px) {
        height:var(--navbar-height);
        box-shadow: 0 5px 15px 0 rgba(0,0,0,0.125);
    }

    @media screen and (max-width: 767px) {
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    }
}

.main-navbar .container.container {
    @media screen and (min-width: 1280px) {
        padding: 0 calc(30rem/16);
    }
    @media screen and (min-width: 768px) {
        max-width: calc(1870rem/16);
    }
    @media screen and (max-width: 768px) {
        padding-right:0;
    }
}
.navbar .navbar-brand{
    margin: 0;
    padding: 0;
    height: auto;
    width: calc(110rem/16);
    @media screen and (min-width: 768px){
        width: calc(160rem/16);
    }
    @media screen and (min-width: 1600px) {
        width: calc(195rem/16);
    }
}
.navbar-brand img{
    max-width: 100%;
    height: auto;
}
.main-navbar .navbar-nav .nav-item{
    position: relative;
}
.main-navbar .navbar-nav .nav-item--dark {
    background: #f0f1f2;
}
.main-navbar .navbar-nav .nav-link{
    color: var(--color-default);
    transition: background 250ms ease;
    line-height: calc(21/16);
    text-transform: uppercase;
    padding: calc(31rem/16) calc(10rem/16);
    overflow: hidden;

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
        height:var(--navbar-height);
    }

    @media screen and (min-width: 1280px) {
        font-size: calc(16rem/16);
        padding: calc(29rem/16) calc(10rem/16);
    }
}


@media screen and (min-width: 768px){
    .main-navbar .navbar-nav .nav-link:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0;
        background: var(--color-primary);
        transition: height 250ms ease;
    }

    .main-navbar .navbar-nav li:hover>.nav-link:after {
        height: calc(6rem/16);
    }
}


.main-navbar .navbar-nav {
    margin-left:calc(10rem/16);
    @media screen and (min-width: 768px){
        margin-left:calc(25rem/16);
    }
    @media screen and (min-width: 1600px) {
        margin-left:calc(80rem/16);
    }
}


@media screen and (min-width: 768px){
    .main-navbar__collapse {
        display: flex;
        flex-basis: auto;
        align-items: center;
        flex-grow: 1;
    }
    .nav-list--sub-level {
        display: none;
        position: absolute;
        top:100%;
        left:0;
        background:#fff;
        width: calc(250rem/16);
        padding-top: calc(15rem/16);
        padding-bottom: calc(15rem/16);
        font-size:.875rem;
        box-shadow: 0 0 calc(30rem/16) rgba(0, 0, 0, 0.4);
    }
    .main-navbar .navbar-nav>.nav-item:hover > .nav-list--sub-level,
    .main-navbar .navbar-nav>.nav-item:focus > .nav-list--sub-level {
        display: block;
    }
    .main-navbar .navbar-nav .nav-list--sub-level .nav-link {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.42857143;
        color: #333;
        font-size:.875rem;
        white-space: nowrap;
        height:auto;
        text-transform: none;
    }
    .main-navbar .navbar-nav .nav-list--sub-level .nav-link:hover,
    .main-navbar .navbar-nav .nav-list--sub-level .nav-link:focus {
        background-color: #f5f5f5;
    }
    .main-navbar .navbar-nav .nav-list--sub-level .nav-link:after {
        display: none !important;
    }
}


.nav-item-toggle {
    display: none;
}

/* mobile fun */
@media screen and (max-width: 767px){
    .navbar__mobile-btn.navbar__mobile-btn.navbar-toggle {
        height:var(--navbar-mobile-height);
        background:  var(--color-primary);
        padding:calc(4rem/16) calc(13rem/16) 0 calc(13rem/16);
        border:0;
        -webkit-appearance: none;
        white-space: nowrap;
        line-height:1;
        margin:0;
        margin-left: calc(10rem/16);
    }
    .navbar__mobile-btn.navbar-toggle .icon-bar {
        display: block;
        width: calc(22rem/16);
        height: calc(2rem/16);
        border-radius: calc(1rem/16);
        margin-left: auto;
        margin-right: auto;
        background-color: #fff;
        transition: transform .25s ease-in-out,opacity .25s ease-in-out;
    }
    .navbar__mobile-btn.navbar-toggle .icon-bar+.icon-bar {
        margin-top: calc(4em/16);
    }
    .navbar__mobile-btn.navbar-toggle.is-active .icon-bar:first-child{
        transform: rotate(-45deg) translateX(-5px) translateY(5px);
    }
    .navbar__mobile-btn.navbar-toggle.is-active .icon-bar:nth-child(2){
        opacity: 0;
    }
    .navbar__mobile-btn.navbar-toggle.is-active .icon-bar:nth-child(3){
        transform: rotate(45deg) translateX(-4px) translateY(-4px);
    }
    .navbar-toggle__text{
        font-size: calc(10rem/16);
        margin-top: calc(5rem/16);
        margin-left: calc(-2rem/16);
        text-transform: uppercase;
        color: #fff;
    }
    .navbar__mobile-btn-container {
        display: flex;
        justify-content: space-between;
        width:calc(100% - 160px);
    }
    .navbar__mobile-btn-container a {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .navbar__mobile-btn {
        font-family:var(--font-default);
        font-weight: 400;
        background: transparent;
        border: none;
        border-radius: 0;
        width: auto;
        text-align: center;
        font-size: 20px;
        height: var(--navbar-mobile-height);
        padding: 0;
        flex: 1 1 100px; /*equal width*/
        position: relative;
    }
    .navbar__mobile-btn:hover,
    .navbar__mobile-btn:focus,
    .navbar__mobile-btn:active {
        background: #ffffff;
        color: var(--color-primary);
    }
    .navbar__mobile-btn .icon, .navbar__mobile-btn-content {
        color: #00205a;
        font-size: calc(20rem/16);
        position: relative;
        left:calc(-2rem/16);
    }
    .navbar__mobile-btn .icon-adult{
        font-size:calc(17rem/16);
        top: calc(1rem/16);
    }
    .navbar__mobile-btn .icon-shop {
        font-size:calc(19rem/16);
        vertical-align: calc(-1rem/16);
    }
    .navbar__mobile-btn-content--count {
        margin-top: calc(2rem/16);
    }


    /* nav */
    .main-navbar__collapse {
        left: 0;
        position: fixed;
        top: var(--navbar-mobile-height);
        bottom: 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        /*transform: translateX(100%);*/
        transition: all 200ms ease-out;
        box-shadow: 0 8px 8px rgba(0, 0, 0, .2);
        z-index: 500;
        background:#fff;
        width:100vw;
        height: calc(100vh - var(--navbar-mobile-height));
        transform: translateX(110%);
    }
    .main-navbar__collapse.is-visible {
        transform: translateX(0);
    }
    .main-navbar .navbar-nav {
        margin:0;
    }
    .main-navbar .navbar-nav .nav-item {
        border-bottom: calc(1rem/16) solid #f0f1f2;
    }
    .main-navbar .navbar-nav .nav-link {
        font-size: calc(18rem/16);
        text-transform: none;
        display: block;
        position: relative;
        padding: calc(15rem/16) calc(15rem/16) calc(12rem/16) calc(20rem/16);
    }



    .nav-item-toggle {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: calc(50rem/16);
        background: transparent;
        border: none;
    }
    .nav-item-toggle .icon {
        font-size: calc(10rem/16);
        transition: transform 240ms;
        transform: rotate(0deg) translateZ(0);
    }
    .nav-item-toggle.is-open >.icon {
        transform: rotate(180deg) translateZ(0);
    }

    .nav-item-toggle:before {
        content: "";
        top: calc(10rem/16);
        bottom: calc(10rem/16);
        border-left: calc(1rem/16) solid #f0f1f2;
        position: absolute;
        left: calc(1rem/16);
    }
    .main-navbar .nav-list--sub-level {
        background: var(--color-grey);
        display:none;
    }
    .main-navbar li ul.nav-list--sub-level.is-open {
        display: block;
    }
    .main-navbar .nav-list--sub-level .nav-link {
        font-size:calc(16rem/16);
        padding-left:calc(40rem/16);
    }
    .main-navbar .nav-list--sub-level .nav-list--sub-level .nav-link {
        padding-left:calc(60rem/16);
    }
    .main-navbar .nav-list--sub-level .nav-list--sub-level .nav-list--sub-level .nav-link {
        padding-left:calc(80rem/16);
    }

}



.btn.navbar__btn {
    color: var(--color-primary);
    background-color: #fff;
    border-radius: 0;
    font-size: 1rem;
    padding: calc(10rem/16) calc(8rem/16);
    font-family: var(--font-light);
    @media screen and (min-width: 1200px) {
        padding: calc(10rem/16) calc(12rem/16);
    }
}

.btn.navbar__btn:hover,
.btn.navbar__btn:focus {
    color: var(--color-primary);
}
.navbar__btn__text {
    display: none;
}

.navbar__btn .navbar__btn-icon {
    transition: all 150ms ease;

}
.navbar__btn:hover .navbar__btn-icon,
.navbar__btn:focus .navbar__btn-icon {
    transform: translateY(-.25rem);

}

.btn.navbar__btn .hit-flag {
    top:0;
    transition: all 150ms ease;

}
.btn.navbar__btn:focus .hit-flag,
.btn.navbar__btn:hover .hit-flag {
    top:0;
    transform: translateY(-.25rem);
}

.navbar__btn-group-item {
    position: relative;
}
@media screen and (min-width: 768px) {
    .navbar__btn-group {
        display: flex;
        align-items: center;
    }
    .navbar__btn-group .navbar__btn-group-item + .navbar__btn-group-item {
        margin-left: calc(2rem/16);
    }
}

