.icon-flag-round {
    background-size: contain;
}

.flag-round {
    width: calc(40rem/16);
    height: calc(40rem/16);
    background-image: url(img/flags_round_24/sprite-flags-round.png);
    background-repeat: no-repeat;
    display: block;
}

.flag-round-flag-AT {
    background-position: calc(-5rem/16) calc(-5rem/16);
}

.flag-round-flag-DE {
    background-position: calc(-55rem/16) calc(-5rem/16);
}

.flag-round-flag-HU {
    background-position: calc(-5rem/16) calc(-55rem/16);
}

.flag-round-flag-NL {
    background-position: calc(-55rem/16) calc(-55rem/16);
}
