.dropdown-menu {
    border: none;
    border-radius: 0;
    box-shadow: 0 0 calc(30rem/16) rgba(0, 0, 0, 0.4);
    color: #333;
    width: calc(250rem/16);
    padding-top: calc(20rem/16);
    padding-bottom: calc(20rem/16);
}
.dropdown-menu--login {
    width: calc(265rem/16);
}
.dropdown-menu>li>a {
    display: block;
    padding: calc(3rem/16) calc(20rem/16);
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    font-size:.875rem;
}

.dropdown-menu-body {
    padding: calc(15rem/16) calc(20rem/16);
}

@media screen and (max-width: 767px) {
    .dropdown-item{
        font-size: calc(14rem/16);
    }
}
.dropdown-menu-body .dropdown__submit{
    width: 100%;
}

.dropdown-menu-body:first-child {
    padding-top: 0;
}
.dropdown-menu-body:last-child {
    padding-bottom: 0;
}

.dropdown-menu--center.dropdown-menu--center {
    left: 50% !important;
    right: auto !important;
    transform: translateX(-50%) !important;
}
.dropdown-menu--has-arrow {
    margin-bottom: calc(14rem/16);
}
.dropdown-menu--has-arrow:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 calc(14rem/16) calc(14rem/16) calc(14rem/16);
    border-color: transparent transparent #ffffff transparent;
}
.dropdown-menu.dropdown-menu--has-arrow-down {
    margin-bottom: calc(45rem/16) !important;
}
.dropdown-menu--has-arrow-down:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: calc(-14rem/16);
    transform: translateX(-50%) rotate(180deg);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 calc(14rem/16) calc(14rem/16) calc(14rem/16);
    border-color: transparent transparent #ffffff transparent;
}
.dropdown-menu-body .dropdown__forgot-password{
    vertical-align: -0.5em;
}

.dropdown-menu--login.dropdown-menu--login {
    left: auto;
    right: calc(-4rem/16);
    transform: translateX(0);
}
.dropdown-menu--login.dropdown-menu--has-arrow:before {
    left: auto;
    right: calc(10rem/16);
    transform: translateX(0);
}