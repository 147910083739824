.confirm-dialog {
    position: fixed;
    left:50%;
    top:25%;
    transform: translateX(-50%) translateY(-20%);
    width:20rem;
    background: #fff;
    padding:2rem;
    box-shadow: 0 0 50px 0 rgba(0,0,0,0.33);
    text-align: center;
    z-index:999;

    @media screen and (min-width: 768px) {
        width:46rem;
        padding:3rem;
    }
}
.confirm-dialog__close {
    border:0;
    -webkit-appearance:none;
    background: transparent;
    color:inherit;
    font-size:calc(15rem/16);
    text-align: center;
    width:calc(45rem/16);
    height:calc(45rem/16);
    padding-top:1rem;
    display: block;
    position: absolute;
    right:0;
    top:0;
    bottom:0;
}

body.iframe .confirm-dialog {
    position: absolute;
    top:50%;
}